import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton({ goToPreviousStep, previousStepName }) {
  return (
    <Tooltip title={`Back: ${previousStepName}`} placement="top">
      <IconButton onClick={goToPreviousStep} aria-label="back">
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
}

export default BackButton;
