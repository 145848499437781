// PasswordResetRequest.js
import React, { useState } from 'react';
import axiosInstance from '../../authentication/axiosInstance';
import {
  Button, TextField, Box, Typography, Container
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';

export default function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    try {
      const response = await axiosInstance.post('/api/password_reset/', JSON.stringify({ email: email }), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        setMessage('Check your email for the reset link');
      }
    } catch (error) {
      setMessage('There was an error sending the reset link');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Reset Link
          </Button>
          {message && (
            <Typography color="textSecondary" align="center">
              {message}
            </Typography>
          )}
          <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Link to="/login" style={{ textDecoration: 'none', marginRight: 10 }}>
              <Typography variant="body2">Login</Typography>
            </Link>
            <Link to="/register-organization" style={{ textDecoration: 'none' }}>
              <Typography variant="body2">Register</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
