// TimeZoneQuestion.js 
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

const TimeZoneQuestion = ({ goToNextStep, goToPreviousStep }) => {
  const [timeZone, setTimeZone] = useState('');
  const { organization } = useAuth();

  const timeZones = [
    { value: 'America/New_York', label: 'Eastern Time - US & Canada' },
    { value: 'America/Chicago', label: 'Central Time - US & Canada' },
    { value: 'America/Denver', label: 'Mountain Time - US & Canada' },
    { value: 'America/Los_Angeles', label: 'Pacific Time - US & Canada' },
    { value: 'America/Anchorage', label: 'Alaska Time - US & Canada' },
    { value: 'Pacific/Honolulu', label: 'Hawaii Time - US & Canada' },
  ];

  const handleTimeZoneChange = (event) => {
    setTimeZone(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Selected Time Zone: ", timeZone);
    goToNextStep();
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const updatedData = {
        time_zone: timeZone
      };

      axiosInstance.put(`/api/organizations/update_time_zone/`, updatedData) 
      .then(response => {
        console.log('Time zone updated successfully:', response.data);
        goToNextStep();
      })
      .catch(error => {
        console.error('Error saving time zone:', error);
      });
    }
    };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Select your time zone:
      </Typography>
      <form onSubmit={handleSubmit}>
        <select value={timeZone} onChange={handleTimeZoneChange} required>
          <option value="">--Please choose a time zone--</option>
          {timeZones.map((zone, index) => (
            <option key={index} value={zone.value}>{zone.label}</option>  
          ))}
        </select>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Tool Importance" />
            <NextButton goToNextStep={saveAndProceed} nextStepName="Base City Selection" />
        </Box>
      </form>
    </Box>
  );
};

export default TimeZoneQuestion;
