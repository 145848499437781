// ProactiveToolsQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function ProactiveToolsQuestion({ goToNextStep, goToPreviousStep }) {
  const [state, setState] = useState({
    liquidAntiIcers: false,
    granularSalt: false,
  });
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data) {
            setState({
              liquidAntiIcers: response.data.uses_liquid_anti_icers || false,
              granularSalt: response.data.uses_granular_salt || false,
            });
          }
        })
        .catch(error => {
          console.error('Error fetching proactive tools preferences:', error);
        });
    }
  }, [organization]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        uses_liquid_anti_icers: state.liquidAntiIcers,
        uses_granular_salt: state.granularSalt,
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving proactive tools preferences:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Do you utilize proactive tools such as pre-treatment with salt and/or liquid anti-icers?
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={state.liquidAntiIcers} onChange={handleChange} name="liquidAntiIcers" />}
          label="I use liquid anti-icers"
        />
        <FormControlLabel
          control={<Checkbox checked={state.granularSalt} onChange={handleChange} name="granularSalt" />}
          label="I pre-treat with granular salt"
        />
      </FormGroup>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Contract Type" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Employee Stand-by Pay" />
      </Box>
    </Box>
  );
}

export default ProactiveToolsQuestion;
