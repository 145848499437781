// Weather.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Grid, Collapse, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import { useAuth } from '../context/AuthContext';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

const WeatherPage = () => {
  const [forecasts, setForecasts] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [hourlyData, setHourlyData] = useState({});
  const [dailySummaries, setDailySummaries] = useState({});
  const [alerts, setAlerts] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const { primarySite, organization } = useAuth();

  // useEffect(() => {
  //   if (!primarySite) {
  //     navigate('/dashboard');
  //   }
  // }, [primarySite, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/weather/forecasts/');
        setForecasts(response.data);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchData();
    const timer = setInterval(() => setCurrentTime(new Date()), 60000);

    return () => clearInterval(timer);
  }, []);

  const handleExpandClick = async (forecast_id) => {
    const isExpanded = expandedIds.includes(forecast_id);
    setExpandedIds(isExpanded ? expandedIds.filter(id => id !== forecast_id) : [...expandedIds, forecast_id]);
  
    if (!isExpanded) {
      const hourlyResponse = await axios.get(`/weather/forecasts/${forecast_id}/hourly/`);
      const twentyFourHourData = hourlyResponse.data
        .filter((value, index, self) => index === self.findIndex((t) => t.hour === value.hour));
      setHourlyData({ ...hourlyData, [forecast_id]: twentyFourHourData });
  
      const dailyResponse = await axios.get(`/weather/forecasts/${forecast_id}/daily/`);
      const processedDailyData = dailyResponse.data.map(daily => ({
        day: daily.summary_date,
        summaries: daily.summary_text.split(' ||| ').filter(text => text.trim())
      }));
      setDailySummaries({ ...dailySummaries, [forecast_id]: processedDailyData });
  
      const alertsResponse = await axios.get(`/weather/forecasts/${forecast_id}/alerts/`);
      setAlerts({ ...alerts, [forecast_id]: alertsResponse.data });
    }
  };

  const formatTime = (date, timeZone) => {
    return timeZone ? moment(date).tz(timeZone).format('h:mm A') : moment(date).format('h:mm A');
  };  

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Weather Forecasts
        </Typography>
        <Grid container spacing={3}>
          {forecasts.map((forecast, index) => (
            <Grid item xs={12} sm={6} lg={4} key={forecast.forecast_id}>
            {organization.access_level === 'demo' && index === 0 && (
              <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                  Only one site's data is available on the Demo plan. Upgrade to have more sites.
                </Typography>
                <Button variant="contained" color="primary">
                  Upgrade
                </Button>
              </Box>
            )}
              <Card>
                <CardContent onClick={() => handleExpandClick(forecast.forecast_id)} style={{ cursor: 'pointer' }}>
                  <Typography variant="h5" component="div">
                    {forecast.city}
                  </Typography>
                  <Typography color="text.secondary">
                    Current Time: {formatTime(currentTime, forecast.time_zone)}
                  </Typography>
                  <Typography variant="body2">
                    {forecast.current_temperature}°C
                  </Typography>
                  <Typography variant="body2">
                    Condition: {forecast.current_condition}
                  </Typography>
                  <Typography variant="body2">
                    Wind: {forecast.current_wind_speed} km/h
                  </Typography>
                </CardContent>
                <Collapse in={expandedIds.includes(forecast.forecast_id)} timeout="auto" unmountOnExit>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Hour</TableCell>
                            <TableCell>Temperature (°C)</TableCell>
                            <TableCell>Forecast</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {hourlyData[forecast.forecast_id]?.map((hourly, index) => (
                            <TableRow key={index}>
                              <TableCell>{`${hourly.hour}:00`}</TableCell>
                              <TableCell>{hourly.temperature}</TableCell>
                              <TableCell>{hourly.forecast}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider style={{ margin: '10px 0' }} />
                    <Typography paragraph>Daily Summary:</Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          {dailySummaries[forecast.forecast_id]?.map((summary, index) => (
                            summary.summaries.map((text, textIndex) => (
                              <TableRow key={`${index}-${textIndex}`}>
                                <TableCell colSpan={4}>{text}</TableCell>
                              </TableRow>
                            ))
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider style={{ margin: '10px 0' }} />
                    <Typography paragraph>Alerts:</Typography>
                    {alerts[forecast.forecast_id]?.map((alert, index) => (
                      <Typography key={index} variant="body2">{`${alert.alert_type}: ${alert.description}`}</Typography>
                    ))}
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default WeatherPage;
