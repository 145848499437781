// ConversationList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import axiosInstance from '../../authentication/axiosInstance';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';

const ConversationList = ({ onSelectConversation, selectedConversation, timeZone }) => {
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/chatbot/conversations/', {
            withCredentials: true
        })
        .then(response => {
            const formattedConversations = response.data.map(convo => {
                // Use moment-timezone to format the conversation dates
                const localizedDate = moment(convo.created_at).tz(timeZone).format('YYYY-MM-DD HH:mm');
                return {
                    title: localizedDate, 
                    description: `ID: ${convo.id}`, 
                    id: convo.id,
                };
            }).sort((a, b) => new Date(b.title) - new Date(a.title)); 
            setConversations(formattedConversations);
        })
        .catch(error => console.error('There was an error fetching the conversations:', error));
    }, [timeZone]); 

    const handleDeleteConversation = (id) => {
        const csrfToken = Cookies.get('csrftoken');
    
        axiosInstance.delete(`/api/chatbot/conversations/${id}/`, {
            headers: {
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        })
        .then(() => {
            setConversations(conversations.filter(convo => convo.id !== id));
        })
        .catch(error => console.error('Error deleting the conversation:', error));
        window.location.reload()
    };

    return (
        <Card variant="outlined" sx={{ width: '100%', maxHeight: '75vh', overflow: 'auto' }}> 
            <List sx={{ py: 2 }}>
                {conversations.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <ListItem disablePadding>
                            <ListItemButton sx={{ justifyContent: 'space-between' }} onClick={() => onSelectConversation(item.id)}>
                                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                    {item.title} 
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.description}
                                </Typography>
                            </ListItemButton>
                            <DeleteIcon 
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleDeleteConversation(item.id);
                                }} 
                                sx={{ color: 'secondary', cursor: 'pointer' }}
                            />
                        </ListItem>
                        {index !== conversations.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </Card>
    );
};

export default ConversationList;