// Theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2C74B3',
    },
    secondary: {
      main: '#31363F',
    },
    background: {
      default: '#1B2430',
      paper: '#1e1e1e',
      sectionDivider: '#333333',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: 'sans-serif, Arial',
  },
  components: {
    // Define default props or styles for MUI components here
  },
  modal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#1e1e1e', 
      color: '#ffffff', 
      border: '1px solid #2C74B3', 
      borderRadius: '8px',
      padding: '20px',
      outline: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 19,
    },
  },
});

export default theme;