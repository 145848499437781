// DebriefPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { differenceInCalendarDays, parseISO, addDays, format } from 'date-fns';
import { Typography, Box } from '@mui/material';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import EventsCard from '../components/Debrief/EventsCard';
import { useAuth } from '../context/AuthContext';
import FormattedMessage from '../components/FormattedDispatchDecision';

function DebriefPage() {
    const { organization } = useAuth(); 
    const [daysSinceCreation, setDaysSinceCreation] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [dailyData, setDailyData] = useState({});
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState('');

    useEffect(() => {
        if (organization) {
            fetchOrganizationCreationDate();
            fetchStations();
        }
    }, [organization]);

    const fetchOrganizationCreationDate = async () => {
        const response = await axios.get('/api/organization/creation-date/');
        const creationDate = parseISO(response.data.created_at);
        const today = new Date();
        const days = Array.from({ length: differenceInCalendarDays(today, creationDate) + 1 })
            .map((_, index) => format(addDays(creationDate, index), 'yyyy-MM-dd'));
        setDaysSinceCreation(days);
        setSelectedDate(days[0]);
    };

    const fetchStations = async () => {
        const { data } = await axios.get(`/api/organization/${organization.id}/stations/`);
        setStations(data);
        if (data.length > 0) {
            setSelectedStation(data[0].id);
        }
    };

    const fetchForecastData = async (day, stationId) => {
        try {
            const response = await axios.get(`/api/hourly-forecasts/${stationId}/${format(day, 'yyyy-MM-dd')}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching forecast data:', error);
            return [];
        }
    };

    const fetchDecisionData = async (day) => {
        try {
            const response = await axios.get(`/api/dispatch-decision/${organization.id}/${day}`);
            return response.data.message; 
        } catch (error) {
            console.error('Failed to fetch dispatch decision:', error);
            return '';
        }
    };

    const fetchHistoricalData = async (day, stationId) => {
        try {
            const response = await axios.get(`/api/historical-forecasts/${stationId}/${format(day, 'yyyy-MM-dd')}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching historical data:', error);
            return [];
        }
    };

    const fetchDailyData = async (day, stationId) => {
        const forecastData = await fetchForecastData(day, stationId);
        const decisionMessage = await fetchDecisionData(day); 
        const historicalData = await fetchHistoricalData(day, stationId);

        setDailyData({
            hourlyForecasts: forecastData,
            dispatchDecision: decisionMessage, 
            historicalData: historicalData
        });
    };

    const handleSelectDay = (day, stationId) => {
        setSelectedDate(day);
        fetchDailyData(day, stationId);
    };

    const handleSelectStation = (stationId) => {
        setSelectedStation(stationId);
        if (selectedDate) {
            fetchDailyData(selectedDate, stationId);
        }
    };

    return (
        <DashboardLayout>
            <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
            Debrief
            </Typography>
            {daysSinceCreation.length > 0 && (
                <EventsCard
                    days={daysSinceCreation}
                    dailyData={dailyData}
                    stations={stations}
                    onSelectDay={handleSelectDay}
                    onSelectStation={handleSelectStation}
                    selectedDate={selectedDate}
                    selectedStation={selectedStation}
                />
            )}
            </Box>
        </DashboardLayout>
    );
}

export default DebriefPage;