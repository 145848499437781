// WebSocket.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

export const WebSocketContext = createContext({
  chatSocket: null,
  decisionMakerSocket: null,
  isBotTyping: false,
  setIsBotTyping: () => {},
  addMessage: () => {},
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [chatSocket, setChatSocket] = useState(null);
  const [decisionMakerSocket, setDecisionMakerSocket] = useState(null);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  
  const addMessage = useCallback((message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  useEffect(() => {
    console.log(`isBotTyping updated: ${isBotTyping}`);
  }, [isBotTyping]);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^http/, 'ws');
    const chatSocketUrl = `${baseUrl}/ws/chatbot/`;
    const decisionMakerSocketUrl = `${baseUrl}/ws/decision_maker/`;

    const newChatSocket = new WebSocket(chatSocketUrl);
    const newDecisionMakerSocket = new WebSocket(decisionMakerSocketUrl);

    newChatSocket.onopen = () => {
      console.log('Chatbot socket connected successfully', newChatSocket);
    };

    newDecisionMakerSocket.onopen = () => {
      console.log('Decision maker socket connected successfully', newDecisionMakerSocket);
    };

    newChatSocket.onmessage = (e) => {
      console.log('WebSocket event received:', e);
      const data = JSON.parse(e.data);
      console.log("Parsed WebSocket data:", data);
      try {
        if ('type' in data && data.type === 'typing_indicator') {
          console.log("Typing indicator received:", data.is_typing);
          setIsBotTyping(data.is_typing);
        } else if (data.type === 'chat_message') {
          console.log("Chat message received:", data.message);
          addMessage({
            sender: data.sender,
            message: data.message,
            conversationId: data.conversationId,
          });
          setIsBotTyping(false);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    newChatSocket.onerror = (event) => {
      console.error('Chatbot socket encountered error: ', event.message);
    };

    newChatSocket.onclose = () => {
      console.error('Chatbot socket closed unexpectedly');
    };

    newDecisionMakerSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === 'dispatch_completed') {
        console.log('Decision maker dispatch completed:', data);
      }
    };

    newDecisionMakerSocket.onerror = (event) => {
      console.error('Decision maker socket encountered error: ', event.message);
    };

    newDecisionMakerSocket.onclose = () => {
      console.error('Decision maker socket closed unexpectedly');
    };

    setChatSocket(newChatSocket);
    setDecisionMakerSocket(newDecisionMakerSocket);

    return () => {
      newChatSocket.close();
      newDecisionMakerSocket.close();
    };
  }, [addMessage]);

  return (
    <WebSocketContext.Provider value={{ chatSocket, decisionMakerSocket, isBotTyping, setIsBotTyping, messages, addMessage, setMessages }}>
      {children}
    </WebSocketContext.Provider>
  );
};
