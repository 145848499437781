// Profile.js
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import axiosInstance from '../authentication/axiosInstance';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const timeZones = [
  { value: 'America/New_York', label: 'Eastern Time - US & Canada' },
  { value: 'America/Chicago', label: 'Central Time - US & Canada' },
  { value: 'America/Denver', label: 'Mountain Time - US & Canada' },
  { value: 'America/Los_Angeles', label: 'Pacific Time - US & Canada' },
  { value: 'America/Anchorage', label: 'Alaska Time - US & Canada' },
  { value: 'Pacific/Honolulu', label: 'Hawaii Time - US & Canada' },
];

const ACCESS_LEVEL_CHOICES = [
  { value: 'demo', label: 'Demo' },
  { value: 'standard', label: 'Standard' },
  { value: 'pro', label: 'Pro' },
];

const UserProfile = () => {
  const { user, primarySite, organization } = useAuth(); 
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [registrationLink, setRegistrationLink] = useState('');
  const [linkGenerationModalOpen, setLinkGenerationModalOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [isNewUserAdmin, setIsNewUserAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState({ email: false, name: false, phone_number: false });
  const [editValues, setEditValues] = useState({
    email: user.user?.email || '',
    user_name: user.user?.user_name || '',
    phone_number: user.user?.phone_number || '',
  });

  useEffect(() => {
    fetchOrganizationUsers();
  }, [user, primarySite, navigate]);

  const fetchOrganizationUsers = () => {
    axiosInstance.get('/api/organizations/users/', { withCredentials: true })
      .then(response => {
          setOrganizationUsers(response.data);
      })
      .catch(error => {
          console.error('Error fetching organization users:', error);
      });
  };

  const handleEditClick = (user) => {
    setSelectedUserForEdit(user);
    setEditModalOpen(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const user_name = formData.get('user_name');
    let is_org_admin = !!formData.get('is_org_admin');
    const phone_number = formData.get('phone_number');

    try {
      await axiosInstance.put(`/api/users/update/${selectedUserForEdit.id}/`, {
        email,
        user_name,
        is_org_admin,
        phone_number,
      }, { withCredentials: true });

      setEditModalOpen(false); 
      fetchOrganizationUsers(); 
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleGenerateLinkSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/api/generate-invitation/', {
        organizationId: user.user?.organization,
        userEmail: newUserEmail,
        userName: newUserName,
        isAdmin: isNewUserAdmin,
      });
      setRegistrationLink(response.data.link);
      setLinkGenerationModalOpen(false);
    } catch (error) {
      console.error('Error generating registration link:', error);
    }
  };

  const handleClick = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleChange = (field, value) => {
    setEditValues({ ...editValues, [field]: value });
  };

  const handleKeyDown = (event, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleBlur(field);
    }
  };

  const handleProfileUpdate = async (userId, updatedData) => {
    try {
      const response = await axiosInstance.put(`api/user/update/${userId}/`, updatedData);
      if (response.status === 200) {
        alert('Profile updated successfully!');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  
  const handleBlur = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    
    if (field === 'time_zone') {
      handleUpdateTimeZone(editValues.time_zone);
    } else {
      const updatedData = { [field]: editValues[field] };
      handleProfileUpdate(user.user.id, updatedData);
    }
  };

  const handleUpdateTimeZone = async (newTimeZone) => {
    try {
      const response = await axiosInstance.put('/api/organizations/update_time_zone/', { time_zone: newTimeZone }, { withCredentials: true });
      if (response.status === 200) {
        alert('Time zone updated successfully!');
      }
    } catch (error) {
      console.error('Error updating time zone:', error);
    }
  };

  const handleDeleteUser = (orgUser) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${orgUser.user_name}?`);
    if (confirmDelete) {
      axiosInstance.delete(`/api/users/delete/${orgUser.id}/`)
        .then(() => {
          alert(`${orgUser.user_name} has been successfully deleted.`);
          fetchOrganizationUsers(); 
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
          alert('Failed to delete the user.');
        });
    }
  };

  const PersonalInfoSection = () => (
    <Box sx={{
      backgroundColor: theme.palette.secondary.main,
      padding: 3,
      borderRadius: 1,
      boxShadow: 3,
      m: 1,
    }}>
      <Typography variant="h5" gutterBottom>Profile</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {isEditing.email ? (
                  <>
                    <TextField
                      sx={{ flexGrow: 1, height: '100%' }}
                      fullWidth
                      variant="standard"
                      value={editValues.email}
                      onChange={(e) => handleChange('email', e.target.value)}
                      onBlur={() => handleBlur('email')}
                      onKeyDown={(e) => handleKeyDown(e, 'email')}
                      autoFocus
                    />
                    <IconButton size="small" onClick={() => handleBlur('email')}>
                      <SaveIcon />
                    </IconButton>
                  </>
                ) : (
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Click to edit" placement="top" arrow>
                      <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => handleClick('email')}>
                        {editValues.email}
                      </Typography>
                    </Tooltip>
                    <IconButton size="small" onClick={() => handleClick('email')}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                {isEditing.user_name ? (
                  <>
                    <TextField
                      sx={{ flexGrow: 1, mr: 1 }}
                      fullWidth
                      variant="standard"
                      value={editValues.user_name}
                      onChange={(e) => handleChange('user_name', e.target.value)}
                      onBlur={() => handleBlur('user_name')}
                      onKeyDown={(e) => handleKeyDown(e, 'user_name')}
                      autoFocus
                    />
                    <IconButton size="small" onClick={() => handleBlur('user_name')}>
                      <SaveIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Click to edit" placement="top" arrow>
                        <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => handleClick('user_name')}>
                          {editValues.user_name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <IconButton size="small" onClick={() => handleClick('user_name')}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                {isEditing.phone_number ? (
                  <>
                    <TextField
                      sx={{ flexGrow: 1, mr: 1 }}
                      fullWidth
                      variant="standard"
                      value={editValues.phone_number}
                      onChange={(e) => handleChange('phone_number', e.target.value)}
                      onBlur={() => handleBlur('phone_number')}
                      onKeyDown={(e) => handleKeyDown(e, 'phone_number')}
                      autoFocus
                    />
                    <IconButton size="small" onClick={() => handleBlur('phone_number')}>
                      <SaveIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Click to edit" placement="top" arrow>
                        <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => handleClick('phone_number')}>
                          {editValues.phone_number}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <IconButton size="small" onClick={() => handleClick('phone_number')}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );

  const OrganizationInfoSection = () => {
    const [isEditingSite, setIsEditingSite] = useState(false);
    const [allSites, setAllSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(organization?.primary_site || null);
    const { refreshOrganizationData } = useAuth();
  
    useEffect(() => {
      const fetchSites = async () => {
        try {
          const response = await axiosInstance.get('/api/sites/');
          setAllSites(response.data);
        } catch (error) {
          console.error('Error fetching sites:', error);
        }
      };
  
      fetchSites();
    }, []);
  
    const handleSiteSelect = (event, newValue) => {
      setSelectedSite(newValue);
    };
  
    const savePrimarySite = async () => {
      setIsEditingSite(false);
      if (!selectedSite || !selectedSite.id) {
          console.error('No site selected');
          return;
      }
  
      try {
          const response = await axiosInstance.put(`/api/update_primary_site/`, {
              site_id: selectedSite.id,
              organization_id: organization.id,
          });
  
          if (response.status === 200) {
              alert('Primary site updated successfully!');
              await refreshOrganizationData(); 
          }
      } catch (error) {
          console.error('Error updating primary site:', error);
          alert('Failed to update the primary site.');
      }
  };
  
    return (
      <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: 3, borderRadius: 1, boxShadow: 3, m: 1 }}>
      <Typography variant="h5" gutterBottom>Organization</Typography>
        <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: 3, borderRadius: 1, boxShadow: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>Information</Typography>
        <Table aria-label="organization information">
          <TableBody>
          {organization && Object.entries(organization).map(([key, value]) => {
            if (key === 'time_zone') {
              return (
                <TableRow key="time_zone">
                  <TableCell component="th" scope="row">Time Zone Preference</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                      {isEditing.time_zone ? (
                        <>
                          <TextField
                            select
                            sx={{ flexGrow: 1, mr: 1 }}
                            value={editValues.time_zone}
                            onChange={(e) => handleChange('time_zone', e.target.value)}
                            onBlur={() => handleBlur('time_zone')}
                            fullWidth
                            variant="standard"
                          >
                            {timeZones.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <IconButton size="small" onClick={() => handleBlur('time_zone')}>
                            <SaveIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <Tooltip title="Click to edit" placement="top" arrow>
                            <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => handleClick('time_zone')}>
                              {editValues.time_zone || organization['time_zone']}
                            </Typography>
                          </Tooltip>
                          {user.user?.is_org_admin && (
                            <IconButton size="small" onClick={() => handleClick('time_zone')}>
                              <EditIcon />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            // } else if (key === 'access_level') {
            //   return (
            //     <TableRow key={key}>
            //       <TableCell component="th" scope="row">Access Level</TableCell>
            //       <TableCell onClick={() => handleClick('access_level')} sx={{ cursor: 'pointer' }}>
            //         {isEditing.access_level ? (
            //           <TextField
            //             select
            //             value={editValues.access_level}
            //             onChange={(e) => handleChange('access_level', e.target.value)}
            //             onBlur={() => handleBlur('access_level')}
            //             fullWidth
            //             variant="standard"
            //           >
            //             {ACCESS_LEVEL_CHOICES.map((option) => (
            //               <MenuItem key={option.value} value={option.value}>
            //                 {option.label}
            //               </MenuItem>
            //             ))}
            //           </TextField>
            //         ) : (
            //           <Tooltip title="Click to edit" placement="top" arrow>
            //             <Typography sx={{ display: 'inline-block' }}>{editValues.access_level || organization[key]}</Typography>
            //           </Tooltip>
            //         )}
            //       </TableCell>
            //     </TableRow>
            //   );
              } else if (key !== 'id' && key !== 'primary_weather_station' && key !== 'primary_site') {
                return (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}</TableCell>
                    <TableCell>{typeof value === 'object' ? JSON.stringify(value) : value.toString()}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
            {organization && organization.primary_site && (
              <TableRow>
                <TableCell>Primary Site Address</TableCell>
                <TableCell>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    minWidth: '500px'
                  }}>
                    {isEditingSite ? (
                      <>
                        <Autocomplete
                          options={allSites}
                          getOptionLabel={(option) => option.address || ''}
                          value={selectedSite}
                          onChange={handleSiteSelect}
                          renderInput={(params) => <TextField {...params} label="Select site" fullWidth />}
                          sx={{ flex: 1 }} // Ensure input takes available space
                        />
                        <IconButton onClick={savePrimarySite} sx={{ ml: 1 }}>
                          <SaveIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Tooltip title="Click to edit" placement="top" arrow>
                        <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => setIsEditingSite(true)}>{selectedSite.address}</Typography>
                        </Tooltip>
                        {user.user?.is_org_admin && (
                          <IconButton onClick={() => setIsEditingSite(true)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {organization && organization.primary_weather_station && (
              <TableRow>
                <TableCell component="th" scope="row">Primary Weather Station</TableCell>
                <TableCell>{organization.primary_weather_station.station_name}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </Box>
        <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: 3, borderRadius: 1, boxShadow: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>Members</Typography>
        <Box>
          {user.user?.is_org_admin && (
            <>
              <Button variant="contained" onClick={() => setLinkGenerationModalOpen(true)}>Generate User Registration Link</Button>
              {registrationLink && <Typography variant="body2" sx={{ wordBreak: 'break-all', mt: 1 }}>{registrationLink}</Typography>}
            </>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table aria-label="organization users">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Phone Number</TableCell>
              <TableCell align="right">Is Admin</TableCell>
              {user.user?.is_org_admin && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationUsers.map((orgUser) => (
              <TableRow key={orgUser.id}>
                <TableCell component="th" scope="row">{orgUser.user_name}</TableCell>
                <TableCell>{orgUser.email}</TableCell>
                <TableCell align="right">{orgUser.phone_number}</TableCell>
                <TableCell align="right">{orgUser.is_org_admin ? 'Yes' : 'No'}</TableCell>
                {user.user?.is_org_admin && (
                  <TableCell align="right">
                    <IconButton onClick={() => handleEditClick(orgUser)} color="primary">
                      <EditIcon />
                    </IconButton>
                    {orgUser.id !== user.user?.id && (
                    <IconButton onClick={() => handleDeleteUser(orgUser)} color="error">
                      <DeleteIcon />
                    </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </Box>
    );
  };
  
  const editModalContent = (
    <Modal
      open={editModalOpen}
      onClose={() => setEditModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      <Box component="form" onSubmit={handleEditSubmit} sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit User
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email"
          name="email"
          defaultValue={selectedUserForEdit?.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Name"
          name="user_name"
          defaultValue={selectedUserForEdit?.user_name}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedUserForEdit?.is_org_admin}
              onChange={(e) => setSelectedUserForEdit({ ...selectedUserForEdit, is_org_admin: e.target.checked })}
              name="is_org_admin"
              color="primary"
            />
          }
          label="Is Organization Admin?"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Phone Number"
          name="phone_number"
          defaultValue={selectedUserForEdit?.phone_number}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Save Changes
        </Button>
        <Button onClick={() => setEditModalOpen(false)}
          fullWidth variant="outlined" sx={{ mt: 1 }}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
  
  const linkGenerationModalContent = (
    <Modal
      open={linkGenerationModalOpen}
      onClose={() => setLinkGenerationModalOpen(false)}
      aria-labelledby="generate-link-modal-title"
      aria-describedby="generate-link-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      <Box component="form" onSubmit={handleGenerateLinkSubmit} sx={modalStyle}>
        <Typography id="generate-link-modal-title" variant="h6" component="h2">
          Generate Registration Link
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email"
          name="newUserEmail"
          onChange={(e) => setNewUserEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Full Name (Optional)"
          name="newUserName"
          onChange={(e) => setNewUserName(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isNewUserAdmin}
              onChange={(e) => setIsNewUserAdmin(e.target.checked)}
              name="isNewUserAdmin"
              color="primary"
            />
          }
          label="Is Admin?"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Send Invite
        </Button>
        <Button onClick={() => setLinkGenerationModalOpen(false)}
          fullWidth variant="outlined" sx={{ mt: 1 }}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
  
  return (
    <DashboardLayout>
      {user ? (
        <>
          <PersonalInfoSection />
          <OrganizationInfoSection />
          {editModalContent}
          {linkGenerationModalOpen && linkGenerationModalContent}
        </>
      ) : (
        <Typography>Loading profile...</Typography>
      )}
    </DashboardLayout>
  );
};

export default UserProfile;