// Chatbot.js
import React, { useState, useEffect, useCallback, useContext } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Paper, Typography, Stack } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ConversationList from '../components/Chatbot/ConversationList';
import MessageList from '../components/Chatbot/MessageList';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import { WebSocketContext } from '../Websocket';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const ChatbotPage = () => {
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [isDispatchLoading, setIsDispatchLoading] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const { decisionMakerSocket } = useContext(WebSocketContext);
    const { organization, primarySite, user } = useAuth();
    const navigate = useNavigate();
    const timeZone = organization?.time_zone || 'America/New_York';

    // useEffect(() => {
    //   if (!primarySite) {
    //     navigate('/dashboard');
    //   }
    // }, [primarySite, navigate]);

    const fetchConversations = useCallback(() => {
        axiosInstance.get('/api/chatbot/conversations/')
            .then(response => {
                const reversedConversations = [...response.data].reverse();
                setConversations(reversedConversations);
                if (reversedConversations.length > 0) {
                    setSelectedConversation(reversedConversations[0].id);
                    const now = new Date();
                    const lastConversationDate = new Date(reversedConversations[0].created_at);
                    const diffTime = Math.abs(now - lastConversationDate);
                    const diffHours = diffTime / (1000 * 60 * 60); 
                    setIsAvailable(diffHours >= 24);
                } else {
                    setIsAvailable(true);
                }
            })
            .catch(error => console.error('Error fetching conversations:', error));
    }, []);

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    useEffect(() => {
        if (decisionMakerSocket) {
            decisionMakerSocket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                if (data.type === 'dispatch_completed') {
                    fetchConversations();
                    window.location.reload();
                }
            };
        }
    }, [decisionMakerSocket, fetchConversations]);

    const handleSendClick = () => {
        setIsDispatchLoading(true);
        axiosInstance.post('/decision-maker/make-decision/', {})
            .then(response => {
                setIsDispatchLoading(false);
                fetchConversations(); 
            })
            .catch(error => {
                console.error('Error sending decision:', error);
                setIsDispatchLoading(false);
            });
    };

    return (
        <DashboardLayout>
            <Box sx={{ display: 'flex' }}>
                <Grid container spacing={0} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Paper sx={{ flexGrow: 1, overflow: 'auto', m: 0, display: 'flex', flexDirection: 'column' }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
                                <Typography variant="h6" component="div">
                                    Conversations
                                </Typography>
                                {user.user?.is_org_admin && (
                                    <LoadingButton
                                        loading={isDispatchLoading}
                                        loadingIndicator="Dispatching..."
                                        variant="contained"
                                        endIcon={<EmojiObjectsIcon />}
                                        onClick={handleSendClick}
                                        disabled={!isAvailable || isDispatchLoading}
                                    >
                                        Dispatch
                                    </LoadingButton>
                                )}
                            </Stack>
                            <ConversationList
                                conversations={conversations}
                                selectedConversation={selectedConversation}
                                onSelectConversation={setSelectedConversation}
                                timeZone={timeZone}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', m: 0 }}>
                            {selectedConversation && <MessageList conversationId={selectedConversation} />}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default ChatbotPage;
