import React, { useEffect, useState } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Checkbox } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const createData = (id, client_name, contact_first_name, contact_last_name, email) => ({
  id, client_name, contact_first_name, contact_last_name, email
});

export default function ClientTable() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState({ id: '', client_name: '', contact_first_name: '', contact_last_name: '', email: '' });
  const [error, setError] = useState(''); 
  const navigate = useNavigate();
  const { primarySite, user, organization } = useAuth();

  // useEffect(() => {
  //   if (!primarySite) {
  //     navigate('/dashboard');
  //   }
  // }, [primarySite, navigate]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/organization_clients/');
      setRows(response.data.map(data => ({
        ...data
      })));
    } catch (error) {
      console.error("There was an error fetching the organization-specific clients data: ", error);
      setError('Failed to fetch data.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = async () => {
    await Promise.all(selected.map(id => axiosInstance.delete(`/api/clients/${id}/`)));
    setSelected([]);
    const fetchData = async () => {
      const response = await axiosInstance.get('/api/clients/');
      setRows(response.data.map(data => createData(data.id, data.client_name, data.contact_first_name, data.contact_last_name, data.email)));
    };
    fetchData();
  };

  const handleDownload = async () => {
    const response = await axiosInstance.get('/api/clients/download/', { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'clients.csv');
    document.body.appendChild(link);
    link.click();
  };

  const updateClientData = async () => {
    try {
      const { id, ...updatedData } = currentClient;
      await axiosInstance.put(`/api/clients/${id}/`, updatedData);
      setEditModalOpen(false);
      setError(''); 
      fetchData();
    } catch (error) {
      console.error("Error updating client:", error);
      setError(error.response.data.email[0] || 'An error occurred');
    }
  };

  const addNewClient = async () => {
    try {
      await axiosInstance.post('/api/clients/', currentClient);
      setAddModalOpen(false);
      setError('');
      fetchData();
    } catch (error) {
      console.error("Error adding new client:", error);
      setError(error.response.data.email[0] || 'An error occurred');
    }
  };

  const handleEditModalOpen = (client) => {
    setCurrentClient(client);
    setEditModalOpen(true);
  };

  const handleAddModalOpen = () => {
    setCurrentClient({ id: '', client_name: '', contact_first_name: '', contact_last_name: '', email: '' });
    setAddModalOpen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      editModalOpen ? updateClientData() : addNewClient();
    }
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Clients
          </Typography>
          <Box>
            {user.user?.is_org_admin && organization?.access_level !== 'demo' ? (
              <>
                <IconButton onClick={handleAddModalOpen} color="text.primary" sx={{ marginRight: '5px' }}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={handleDelete} color="error" sx={{ marginLeft: '5px' }}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              console.log('Action buttons not displayed due to insufficient permissions or demo access level')
            )}
            <IconButton onClick={handleDownload} color="primary">
              <DownloadIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all clients' }}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Contact First Name</TableCell>
                <TableCell>Contact Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell> 
                    <TableCell>{row.client_name}</TableCell>
                    <TableCell>{row.contact_first_name}</TableCell>
                    <TableCell>{row.contact_last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEditModalOpen(row)} color="text.primary">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={editModalOpen || addModalOpen}
        onClose={() => { setEditModalOpen(false); setAddModalOpen(false); setError(''); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {editModalOpen ? 'Edit Client' : 'Add New Client'}
          </Typography>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, mt: 2 }}
            noValidate
            autoComplete="off"
            onKeyPress={handleKeyPress}
          >
            <TextField
              required
              id="client_name"
              label="Client Name"
              defaultValue={currentClient.client_name}
              onChange={(e) => setCurrentClient({ ...currentClient, client_name: e.target.value })}
            />
            <TextField
              required
              id="contact_first_name"
              label="Contact First Name"
              defaultValue={currentClient.contact_first_name}
              onChange={(e) => setCurrentClient({ ...currentClient, contact_first_name: e.target.value })}
            />
            <TextField
              id="contact_last_name"
              label="Contact Last Name"
              defaultValue={currentClient.contact_last_name}
              onChange={(e) => setCurrentClient({ ...currentClient, contact_last_name: e.target.value })}
            />
            <TextField
              required
              id="email"
              label="Email"
              defaultValue={currentClient.email}
              onChange={(e) => setCurrentClient({ ...currentClient, email: e.target.value })}
            />
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button onClick={() => { setEditModalOpen(false); setAddModalOpen(false); }} sx={{ mr: 1 }}>Cancel</Button>
              <Button variant="contained" onClick={editModalOpen ? updateClientData : addNewClient}>Save</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}
