// EventsCard.js
import React, { useState } from 'react';
import { Card, List, ListItem, ListItemButton, Typography, Collapse, Divider, Box, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import DailyForecast from './ForecastsDataCard';
import HistoricalForecast from './HistoricalDataCard';
import DispatchDecision from './DispatchCard';
import CommentsSection from './CommentsCard';
import WeatherStationSelector from './StationSelector';

function EventsCard({ days, onSelectDay, dailyData, stations, selectedStation, onSelectStation }) {
    const [selectedDay, setSelectedDay] = useState(null);

    const toggleDay = (day) => {
        if (selectedDay === day) {
            setSelectedDay(null);
        } else {
            setSelectedDay(day);
            onSelectDay(day);
        }
    };

    const handleStationChange = (event) => {
        const newStationId = event.target.value;
        onSelectStation(newStationId);
    };

    return (
        <Card variant="outlined" sx={{ width: '100%', maxHeight: '75vh', overflow: 'auto' }}>
            <List sx={{ py: 2 }}>
                {days.map((day) => (
                    <React.Fragment key={day}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => toggleDay(day)}>
                                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                    {format(parseISO(day), 'PPP')}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={selectedDay === day} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box sx={{ margin: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Detailed Information for {format(parseISO(day), 'PPP')}
                                </Typography>
                                <WeatherStationSelector
                                    stations={stations}
                                    selectedStation={selectedStation}
                                    onChange={handleStationChange}
                                />
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Weather Analysis
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5.85} marginRight={2} marginBottom={2}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Forecasted Data
                                        </Typography>
                                        <DailyForecast forecastData={dailyData.hourlyForecasts || []} />
                                    </Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid item xs={12} md={5.85} marginRight={2} marginBottom={2}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Historical Data
                                        </Typography>
                                        <HistoricalForecast historicalData={dailyData.historicalData || []} />
                                    </Grid>
                                </Grid>
                                <DispatchDecision decisionSections={dailyData.dispatchDecision} />
                                <CommentsSection comments={dailyData?.comments} />
                            </Box>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
        </Card>
    );
}

export default EventsCard;
