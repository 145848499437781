// src/context/CsrfContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCsrfToken } from '../authentication/csrf';
import Cookies from 'js-cookie';

const CsrfContext = createContext();

export const useCsrf = () => useContext(CsrfContext);

export const CsrfProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const token = await getCsrfToken();
      setCsrfToken(token);
      Cookies.remove('csrftoken'); 
      Cookies.set('csrftoken', token, { secure: true, sameSite: 'Strict' }); 
    };

    fetchCsrfToken();
  }, []);

  return (
    <CsrfContext.Provider value={csrfToken}>
      {children}
    </CsrfContext.Provider>
  );
};
