// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(true);
    const [primarySite, setPrimarySite] = useState(null);

    useEffect(() => {
        const fetchOrganizationData = async () => {
            if (user && user.user && user.user.organization) {
                try {
                    const orgResponse = await axiosInstance.get(`/api/organizations/get_organization_details/`);
                    setOrganization(orgResponse.data);
                } catch (error) {
                    console.error('Error fetching organization data:', error);
                    setOrganization(null);
                }
            }
        };

        fetchOrganizationData();
    }, [user]);

    const refreshOrganizationData = async () => {
        if (user && user.user && user.user.organization) {
            try {
                const orgResponse = await axiosInstance.get(`/api/organizations/get_organization_details/`);
                setOrganization(orgResponse.data);

                if (orgResponse.data && orgResponse.data.primary_site_id) {
                    const siteResponse = await axiosInstance.get(`/api/sites/${orgResponse.data.primary_site_id}/`);
                    setPrimarySite(siteResponse.data);
                } else {
                    setPrimarySite(null);
                }
            } catch (error) {
                console.error('Error refreshing organization data:', error);
            }
        }
    };

    useEffect(() => {
        const fetchPrimarySiteData = async () => {
            if (organization) {
                try {
                    const response = await axiosInstance.get('/api/organizations/primary_site/');
                    if (response.data && response.data.id) {
                        setPrimarySite(response.data);
                    } else {
                        setPrimarySite(null);
                    }
                } catch (error) {
                    console.error('Error fetching primary site data:', error);
                    setPrimarySite(null);
                }
            }
        };

        fetchPrimarySiteData();
    }, [organization]);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const authResponse = await axiosInstance.get('/api/check-authentication');
                if (authResponse.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    const userResponse = await axiosInstance.get('/api/user');
                    setUser(userResponse.data);
                } else {
                    setIsAuthenticated(false);
                    setUser(null);
                    setOrganization(null);
                }
            } catch (error) {
                setIsAuthenticated(false);
                setUser(null);
                setOrganization(null);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, []);

    const logoutUser = async () => {
        setIsAuthenticated(false);
        setUser(null);
        setOrganization(null);
        try {
            const csrfToken = Cookies.get('csrftoken');
            await axiosInstance.post('/api/logout', {}, {
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            });
            console.log('User logged out successfully.');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const loginUser = async (credentials) => {
        try {
            const response = await axiosInstance.post('/api/login', credentials);
            const { token } = response.data;
            localStorage.setItem('authToken', token);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setIsAuthenticated(true);
            setUser(response.data.user);
        } catch (error) {
            console.error('Login error:', error);
            setIsAuthenticated(false);
            setUser(null);
        }
    };

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            setIsAuthenticated,
            user, 
            setUser,
            primarySite,
            organization,
            loading, 
            logoutUser,
            refreshOrganizationData,
            loginUser,
        }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
