// SubcontractorsQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function SubcontractorsQuestion({ goToNextStep, goToPreviousStep }) {
  const [useSubcontractors, setUseSubcontractors] = useState('');
  const [contractStructure, setContractStructure] = useState('');
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data) {
            setUseSubcontractors(response.data.uses_subcontractors ? 'yes' : 'no');
            setContractStructure(response.data.subcontractor_contract_type || '');
          }
        })
        .catch(error => {
          console.error('Error fetching subcontractor preferences:', error);
        });
    }
  }, [organization]);

  const handleSubcontractorChange = (event) => {
    setUseSubcontractors(event.target.value);
  };

  const handleStructureChange = (event) => {
    setContractStructure(event.target.value);
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        uses_subcontractors: useSubcontractors === 'yes',
        subcontractor_contract_type: useSubcontractors === 'yes' ? contractStructure : null,
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving subcontractor preferences:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>Do you utilize any subcontractors?</Typography>
      <RadioGroup
        aria-label="subcontractors-use"
        name="subcontractors-use"
        value={useSubcontractors}
        onChange={handleSubcontractorChange}
        sx={{ mb: 2 }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      {useSubcontractors === 'yes' && (
        <>
          <Typography variant="h6" gutterBottom>What is the most common contract structure for your subcontractors?</Typography>
          <RadioGroup
            aria-label="contract-structure"
            name="contract-structure"
            value={contractStructure}
            onChange={handleStructureChange}
          >
            <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
            <FormControlLabel value="time-material" control={<Radio />} label="Time and Material" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </>
      )}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Stand-by Pay" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Snow Depth" />
      </Box>
    </Box>
  );
}

export default SubcontractorsQuestion;
