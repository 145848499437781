// SnowDepthResponseQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function SnowDepthResponseQuestion({ goToNextStep, goToPreviousStep }) {
  const [snowDepth, setSnowDepth] = useState('');
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data && response.data.required_snow_depth_response !== null) {
            setSnowDepth(response.data.required_snow_depth_response.toString());
          }
        })
        .catch(error => {
          console.error('Error fetching snow depth response preference:', error);
        });
    }
  }, [organization]);

  const handleSnowDepthChange = (event) => {
    setSnowDepth(event.target.value);
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        required_snow_depth_response: parseFloat(snowDepth),
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving snow depth response preference:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>In general, at what snow depth are you required to respond to your clients?</Typography>
      <TextField
        label="Required snow depth (inches)"
        type="number"
        value={snowDepth}
        onChange={handleSnowDepthChange}
        variant="outlined"
        sx={{ mb: 2 }}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Subcontractors" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="24/7 Facilities" />
      </Box>
    </Box>
  );
}

export default SnowDepthResponseQuestion;
