// OnboardingHandler.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import WelcomeNote from './WelcomeNote';
import ServiceTypeQuestion from './ServiceTypeQuestion';
import ContractTypeQuestion from './ContractTypeQuestion';
import ProactiveToolsQuestion from './ProactiveToolsQuestion';
import StandbyPayQuestion from './StandbyPayQuestion';
import SubcontractorsQuestion from './SubcontractorsQuestion';
import SnowDepthResponseQuestion from './SnowDepthResponseQuestion';
import FacilitiesOperationQuestion from './FacilitiesOperationQuestion';
import ToolImportanceQuestion from './ToolImportanceQuestion';
import TimeZoneQuestion from './TimeZoneQuestion';
import BaseCityModal from './BaseCityModal';
import FinalOnboardingComponent from './CompleteOnboarding';

Modal.setAppElement('#root');

const customModalStyle = {
  content: {
    height: '50%',
    width: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    border: '1px solid #2C74B3',
    borderRadius: '8px',
    padding: '20px',
    outline: 'none',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 20,
  },
};

const steps = [
  WelcomeNote,
  ServiceTypeQuestion,
  ContractTypeQuestion,
  ProactiveToolsQuestion,
  StandbyPayQuestion,
  SubcontractorsQuestion,
  SnowDepthResponseQuestion,
  FacilitiesOperationQuestion,
  ToolImportanceQuestion,
  TimeZoneQuestion,
  BaseCityModal,
  FinalOnboardingComponent
];

function OnboardingHandler({}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

  useEffect(() => {
    const onboardingProgress = localStorage.getItem('onboardingProgress');
    const isComplete = onboardingProgress && parseInt(onboardingProgress, 10) >= steps.length;
    setIsOnboardingComplete(isComplete);
  }, []);

  const validateOnboardingCompletion = () => {
    return true;
  };

  const completeOnboarding = () => {
    if (validateOnboardingCompletion()) {
      setIsOnboardingComplete(true);
    }
  };

  const goToNextStep = () => {
    const nextStepIndex = currentStepIndex + 1;
    setCurrentStepIndex(nextStepIndex);
    localStorage.setItem('onboardingProgress', nextStepIndex.toString());
  };

  const goToPreviousStep = () => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex >= 0) {
      setCurrentStepIndex(previousStepIndex);
      localStorage.setItem('onboardingProgress', previousStepIndex.toString());
    }
  };

  const progressPercentage = (currentStepIndex / (steps.length - 1)) * 100;

  const CurrentStepComponent = steps[currentStepIndex];

  return (
    <Modal
      isOpen={!isOnboardingComplete}
      contentLabel="Onboarding Steps"
      onRequestClose={() => {}}
      style={customModalStyle}
    >
      <div style={{ width: '100%', backgroundColor: '#ccc' }}>
        <div 
          style={{ 
            height: '4px', 
            backgroundColor: '#2C74B3', 
            width: `${progressPercentage}%`, 
            transition: 'width 0.5s ease' 
          }}
        />
      </div>
      <CurrentStepComponent
        completeOnboarding={completeOnboarding}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        isVisible={currentStepIndex === steps.indexOf(BaseCityModal)}
        progressPercentage={progressPercentage}
      />
    </Modal>
  );
}

export default OnboardingHandler;