// NextButton.js
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function NextButton({ goToNextStep, nextStepName }) {
  const handleClick = () => {
    console.log('Next button clicked');
    if (goToNextStep) {
      goToNextStep();
    }
  };
  return (
    <Tooltip title={`Next: ${nextStepName}`} placement="top">
      <IconButton onClick={goToNextStep} aria-label="next">
        <ArrowForwardIcon />
      </IconButton>
    </Tooltip>
  );
}

export default NextButton;
