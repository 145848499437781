// ContractTypeQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function ContractTypeQuestion({ goToNextStep, goToPreviousStep }) {
  const [value, setValue] = useState('');
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data && response.data.contract_type) {
            setValue(response.data.contract_type);
          }
        })
        .catch(error => {
          console.error('Error fetching the contract type preference:', error);
        });
    }
  }, [organization]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        contract_type: value,
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving the contract type preference:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Are the majority of your snow removal contracts all-inclusive, time and material, or per-event?
      </Typography>
      <RadioGroup
        aria-label="contract-type"
        name="contract-type"
        value={value}
        onChange={handleChange}
        sx={{ mb: 2 }}
      >
        <FormControlLabel value="all-inclusive" control={<Radio />} label="All-inclusive" />
        <FormControlLabel value="time-material" control={<Radio />} label="Time and Material" />
        <FormControlLabel value="per-event" control={<Radio />} label="Per-event" />
      </RadioGroup>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Service Type" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Proactive Tools" />
      </Box>
    </Box>
  );
}

export default ContractTypeQuestion;
