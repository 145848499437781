// src/authentication/axiosInstance.js
import axios from 'axios';
import Cookies from 'js-cookie';

// Creating an instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Adding a request interceptor to set CSRF token dynamically
axiosInstance.interceptors.request.use((config) => {
  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
});

// Adding a response interceptor
axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  console.error('Error response:', error.response);
  return Promise.reject(error);
});

export default axiosInstance;
