// App.js
import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './authentication/ProtectedRoute';
import { WebSocketProvider } from './Websocket';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/Theme';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import { LoadScript } from '@react-google-maps/api';

// Import page components
import Landing from './pages/Landing/Landing';
import LoginSide from './pages/Login';
import Register from './pages/UserRegistration';
import OrganizationRegistration from './pages/OrganizationRegistration';
import WeatherPage from './pages/Weather';
import ChatbotPage from './pages/Chatbot';
import HomePage from './pages/Home';
import UserProfile from './pages/Profile';
import ClientTable from './pages/Clients';
import SiteTable from './pages/Sites';
import PasswordResetForm from './components/PasswordReset/PasswordResetForm';
import PasswordResetRequest from './components/PasswordReset/PasswordResetRequest';
import DebriefPage from './pages/Debrief';

// Set up Axios defaults for CSRF protection and credentials
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/register-organization" element={<OrganizationRegistration />} />
              <Route path="/login" element={<LoginSide />} />
              <Route path="/register" element={<Register />} />
              <Route path="/password-reset" element={<PasswordResetRequest />} />
              <Route path="/reset/:uid/:token" element={<PasswordResetForm />} />
              {/* Protected routes */}
              <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
              <Route path="/weather" element={<ProtectedRoute><WeatherPage /></ProtectedRoute>} />
              <Route path="/chatbot" element={
                <ProtectedRoute>
                  <WebSocketProvider>
                    <ChatbotPage />
                  </WebSocketProvider>
                </ProtectedRoute>
              } />
              <Route path="/debrief" element={<ProtectedRoute><DebriefPage /></ProtectedRoute>} />
              <Route path="/clients" element={<ProtectedRoute><ClientTable /></ProtectedRoute>} />
              <Route path="/sites" element={<ProtectedRoute><SiteTable /></ProtectedRoute>} />
            </Routes>
          </Router>
        </LoadScript>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;