// Sites.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import Modal from 'react-modal';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, IconButton, Toolbar, Typography, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { GoogleMap, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const modalStyle = {
  content: {
    height: '80%',
    width: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1e1e1e', 
    color: '#ffffff', 
    border: '1px solid #2C74B3', 
    borderRadius: '8px',
    padding: '20px',
    outline: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 20,
  },
};

export default function SiteTable() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentSite, setCurrentSite] = useState({
    id: '', name: '', address: '', city: '', province: '', postal_code: '', weather_station: '', walks_area: '', lots_area: '', latitude: '', longitude: '', geofence: ''
  });
  const [error, setError] = useState('');
  const searchBoxRef = React.useRef(null);
  const { organization, primarySite, user } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!primarySite) {
  //     navigate('/dashboard');
  //   }
  // }, [primarySite, navigate]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/sites/');
      setRows(response.data.map((site, index) => ({
        ...site,
        number: index + 1 
      })));
    } catch (error) {
      console.error("There was an error fetching the sites data: ", error);
      setError('Failed to fetch data.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = async () => {
    await Promise.all(selected.map(id => axiosInstance.delete(`/api/sites/${id}/`)));
    setSelected([]);
    fetchData();
  };

  const handleDownload = async () => {
    const response = await axiosInstance.get('/api/sites/download/', { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sites.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setAddModalOpen(false);
    setError('');
    setCurrentSite({
      id: '', name: '', address: '', city: '', province: '', postal_code: '', weather_station: '', walks_area: '', lots_area: '', latitude: '', longitude: '', geofence: ''
    });
  };

  const updateSiteData = async () => {
    try {
      await axiosInstance.put(`/api/sites/${currentSite.id}/`, currentSite);
      handleModalClose();
      fetchData();
    } catch (error) {
      console.error("Error updating site:", error);
      setError(error.response.data.detail || 'An error occurred');
    }
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      setCurrentSite({
        ...currentSite,
        address: place.formatted_address,
        city: getComponent(place.address_components, 'locality'),
        province: getComponent(place.address_components, 'administrative_area_level_1', true),
        postal_code: getComponent(place.address_components, 'postal_code'),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        walks_area: currentSite.walks_area || null,
        lots_area: currentSite.lots_area || null,
        geofence: currentSite.geofence || null,
      });
    }
  };
  
  const getComponent = (components, type, isProvince = false) => {
    let result = components.find(component => component.types.includes(type));
    if (isProvince && result) {
      return mapProvinceToCode(result.long_name);
    }
    return result ? result.long_name : '';
  };
  
  const addNewSite = async () => {
    const siteData = {
      ...currentSite,
      organization: organization?.id, 
      province: currentSite.province ? mapProvinceToCode(currentSite.province) : null,
      walks_area: currentSite.walks_area ? parseInt(currentSite.walks_area, 10) : null,
      lots_area: currentSite.lots_area ? parseInt(currentSite.lots_area, 10) : null,
      geofence: currentSite.geofence ? parseInt(currentSite.geofence, 10) : null,
    };
    console.log('Site info: ', siteData)
  
    try {
      const response = await axiosInstance.post('/api/sites/', siteData);
      handleModalClose();
      fetchData();
    } catch (error) {
      console.error("Error adding new site:", error);
      setError(error.response?.data?.detail || 'An error occurred');
      console.log("Error data:", error.response?.data);
    }
  };

  const mapProvinceToCode = (provinceOrStateName) => {
    const provinceStateMap = {
      // Canadian Provinces
      'Alberta': 'AB',
      'British Columbia': 'BC',
      'Manitoba': 'MB',
      'New Brunswick': 'NB',
      'Newfoundland and Labrador': 'NL',
      'Nova Scotia': 'NS',
      'Ontario': 'ON',
      'Prince Edward Island': 'PE',
      'Quebec': 'QC',
      'Saskatchewan': 'SK',
      'Northwest Territories': 'NT',
      'Nunavut': 'NU',
      'Yukon': 'YT',
      // U.S. States
      'Alaska': 'AK',
      'Colorado': 'CO',
      'Idaho': 'ID',
      'Maine': 'ME',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Montana': 'MT',
      'New Hampshire': 'NH',
      'New York': 'NY',
      'North Dakota': 'ND',
      'South Dakota': 'SD',
      'Vermont': 'VT',
      'Washington': 'WA',
      'Wisconsin': 'WI',
      'Wyoming': 'WY',
    };
  
    return provinceStateMap[provinceOrStateName] || provinceOrStateName;
  };

  const handleEditModalOpen = (site) => {
    setCurrentSite({
      ...site,
      latitude: site.latitude || 45,
      longitude: site.longitude || -95 
    });
    setEditModalOpen(true);
  };

  const handleAddModalOpen = () => {
    handleModalClose();
    setAddModalOpen(true);
  };

  const handleSiteChange = (event) => {
    const { name, value } = event.target;
    setCurrentSite(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      editModalOpen ? updateSiteData() : addNewSite();
    }
  };

  const onSearchBoxLoaded = (ref) => {
    searchBoxRef.current = ref;
  };
  
  const onMarkerDragEnd = (event) => {
    setCurrentSite({
      ...currentSite,
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  return (
    <DashboardLayout>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" id="tableTitle" component="div">
              Sites
            </Typography>
            <Box>
              {user.user?.is_org_admin && organization?.access_level !== 'demo' ? (
                <>
                  <IconButton onClick={handleAddModalOpen} color="text.primary" sx={{ marginRight: '5px' }}>
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete} color="error" sx={{ marginLeft: '5px' }}>
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                console.log('Action buttons not displayed due to insufficient permissions or demo access level')
              )}
              <IconButton onClick={handleDownload} color="primary">
                <DownloadIcon />
              </IconButton>
            </Box>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell></TableCell> 
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Province/State</TableCell>
                  <TableCell>Postal Code</TableCell>
                  <TableCell>Weather Station</TableCell>
                  <TableCell>Walks Area</TableCell>
                  <TableCell>Lots Area</TableCell>
                  <TableCell>Latitude</TableCell>
                  <TableCell>Longitude</TableCell>
                  <TableCell>Geofence</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.province}</TableCell>
                      <TableCell>{row.postal_code}</TableCell>
                      <TableCell>{row.weather_station ? row.weather_station.station_name : 'N/A'}</TableCell> 
                      <TableCell>{row.walks_area}</TableCell>
                      <TableCell>{row.lots_area}</TableCell>
                      <TableCell>{row.latitude}</TableCell>
                      <TableCell>{row.longitude}</TableCell>
                      <TableCell>{row.geofence}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleEditModalOpen(row)} color="text.primary">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {/* Modal for editing and adding new sites */}
      <Modal
        isOpen={editModalOpen || addModalOpen}
        onRequestClose={handleModalClose}
        style={modalStyle}
        contentLabel={editModalOpen ? "Edit Site" : "Add New Site"}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            {editModalOpen ? 'Edit Site' : 'Add New Site'}
          </Typography>
          <StandaloneSearchBox onLoad={onSearchBoxLoaded} onPlacesChanged={onPlacesChanged}>
            <TextField
              fullWidth
              label="Search Address"
              variant="outlined"
              placeholder="Type your address"
              defaultValue={editModalOpen ? currentSite.address : ''}
              onChange={(e) => {
                setCurrentSite({ ...currentSite, address: e.target.value });
              }}
            />
          </StandaloneSearchBox>
          <Box sx={{ height: '45vh', width: '100%', marginTop: 2 }}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={{ lat: currentSite.latitude || 45, lng: currentSite.longitude || -95 }}
            zoom={5}
          >
            {currentSite.latitude && currentSite.longitude && (
              <Marker
                position={{ lat: currentSite.latitude, lng: currentSite.longitude }}
                draggable={true}
                onDragEnd={(e) => {
                  setCurrentSite({ ...currentSite, latitude: e.latLng.lat(), longitude: e.latLng.lng() });
                }}
              />
            )}
          </GoogleMap>
          </Box>
          <TextField
            margin="dense"
            name="name"
            label="Site Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentSite.name || ''}
            onChange={handleSiteChange}
            helperText="Optional"
          />
          <TextField
            margin="dense"
            name="walks_area"
            label="Walks Area (sqft)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentSite.walks_area}
            onChange={handleSiteChange}
            helperText="Optional"
          />
          <TextField
            margin="dense"
            name="lots_area"
            label="Lots Area (sqft)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentSite.lots_area}
            onChange={handleSiteChange}
            helperText="Optional"
          />
          <TextField
            margin="dense"
            name="geofence"
            label="Geofence Radius (m)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentSite.geofence}
            onChange={handleSiteChange}
            helperText="Optional"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={addModalOpen ? addNewSite : updateSiteData} color="primary">Save</Button>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}