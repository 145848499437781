// Login.js
import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import { useAuth } from '../context/AuthContext';
import {
  Avatar, Button, CssBaseline, TextField,
  FormControlLabel, Checkbox, Link, Paper, Box, Grid, Typography
} from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.constellationai.com/">
        Consalt
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LoginSide() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setUser, setIsAuthenticated } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      setErrorMessage('Email and password must not be blank.');
      return;
    }
  
    try {
      console.log('Attempting to log in with', { email, password });
      console.log('Request to', process.env.REACT_APP_API_BASE_URL);

      const response = await axiosInstance.post('/api/login', { email, password });

      if (response.status === 200) {
        setIsAuthenticated(true);
        setUser(response.data.user);
        window.location.assign("/dashboard");
      } else {
        setErrorMessage('Login failed. Please check your credentials.');
        console.log('Login failed:', response);
      }
    } catch (error) {
      setErrorMessage('Login failed. Please check your credentials.');
      console.log('Login error:', error);
    }
  };

  // const checkAuthentication = useCallback(async () => {
  //   try {
  //     const response = await axiosInstance.get('/api/check-authentication');
  //     if (response.data.isAuthenticated) {
  //       window.location.assign("/dashboard");
  //     }
  //   } catch (error) {
  //     console.log('User is not authenticated, staying on the login page.');
  //   }
  // }, []);

  // useEffect(() => {
  //   checkAuthentication();
  // }, [checkAuthentication]);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item xs={false} sm={4} md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Box sx={{ alignSelf: 'flex-start', mb: 2 }}>
            <Button
              variant="contained"
              href="/"
              sx={{ ml: 2, mt: -6 }}
            >
              Back to Home
            </Button>
          </Box>
          <Avatar sx={{ m: 1, bgcolor: 'white' }}>
            <img src={"/images/Consalt_Favicon.png"} alt="favicon" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {errorMessage && (
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
              <Link href="/password-reset" variant="body2">
                Forgot password?
              </Link>
              </Grid>
              <Grid item>
                <Link href="/register-organization" variant="body2">
                  {"Not set up yet? Register here!"}
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
