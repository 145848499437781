// HistoricalDataCard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const HistoricalForecast = ({ historicalData }) => {
    // Assuming historicalData is an array of { time, temperature, precipitation }
    const formattedData = historicalData.map(item => ({
        ...item,
        time: new Date(item.time).getHours(), // Extract the hour from time
        temperature: parseFloat(item.temperature),
        precipitation: parseFloat(item.precipitation)
    }));

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Historical Hourly Data</Typography>
                <LineChart
                    width={600} // Adjust as needed
                    height={300}
                    data={formattedData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="temperature" stroke="#8884d8" />
                    <Line yAxisId="right" type="monotone" dataKey="precipitation" stroke="#82ca9d" />
                </LineChart>
            </CardContent>
        </Card>
    );
};

export default HistoricalForecast;
