// DispatchCard.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import FormattedMessage from '../FormattedDispatchDecision';

const DispatchDecision = ({ decisionSections = '' }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Dispatch Decision</Typography>
        <FormattedMessage message={decisionSections} />
      </CardContent>
    </Card>
  );
};

export default DispatchDecision;

