import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function WelcomeNote({ goToNextStep }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: 3,
      }}
    >
      <Typography variant="h5" gutterBottom>Welcome to ConStellation!</Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
        For us to tailor your AI to help you better, please complete the following onboarding process.
      </Typography>
      <Box sx={{ marginTop: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={goToNextStep}>
          Let's Get Started
        </Button>
      </Box>
    </Box>
  );
}

export default WelcomeNote;
