// HomePage.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import { GoogleMap, Marker } from '@react-google-maps/api';
import theme from '../components/Theme';
import { useAuth } from '../context/AuthContext';
import OnboardingHandler from '../components/Onboarding/OnboardingHandler';
import { useNavigate } from 'react-router-dom';
import FormattedMessage from '../components/FormattedDispatchDecision';

import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import CloudIcon from '@mui/icons-material/Cloud';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import GrainIcon from '@mui/icons-material/Grain';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import OpacityIcon from '@mui/icons-material/Opacity';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const weatherKeywords = {
  'snow | flurries': <AcUnitIcon />,
  'partly cloudy | mix': <FilterDramaIcon />,
  'clear': <NightsStayIcon />,
  'cloudy | cloudiness': <CloudIcon />,
  'rain | showers | raining': <GrainIcon />,
  'thunderstorm': <ThunderstormIcon />,
  'heavy rain': <OpacityIcon />,
  'windy': <BeachAccessIcon />,
  'sunny | sun': <WbSunnyIcon />,
  // Add more keywords and icons as needed
};

const HomePage = () => {
  const { organization } = useAuth();
  const [sites, setSites] = useState([]);
  const [forecasts, setForecasts] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [map, setMap] = useState(null);
  const [hourlyForecasts, setHourlyForecasts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [dailySummary, setDailySummary] = useState(null);
  const [dailySummaryText, setDailySummaryText] = useState('');
  const [dispatchDecision, setDispatchDecision] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [isOnboardingStatusLoaded, setIsOnboardingStatusLoaded] = useState(false);

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const orgResponse = await axiosInstance.get(`/api/organizations/get_organization_details/`);
        if (orgResponse.data && orgResponse.data.onboarding_completed) {
          setIsOnboardingComplete(true);
        }
      } catch (error) {
        console.error('Error fetching organization details:', error);
      } finally {
        setIsOnboardingStatusLoaded(true);
      }
    };
  
    if (organization) {
      fetchOrganizationDetails();
    }
  }, [organization]);  

  useEffect(() => {
    const fetchPrimarySiteAndForecasts = async () => {
      try {
        const organizationResponse = await axiosInstance.get('/api/organizations/primary_site/');
        const primarySite = organizationResponse.data;
        setSelectedSite(primarySite);
        
        if (primarySite) {
          const weatherStationId = organizationResponse.data.weather_station.id;
          if (weatherStationId) {
            const forecastResponse = await axiosInstance.get(`/weather/station/${weatherStationId}/latest_forecast/`);
            const primaryForecast = forecastResponse.data;
    
            if (primaryForecast) {
              setForecasts([primaryForecast]);
              fetchHourlyForecasts(primaryForecast.forecast_id);
              fetchAlerts(primaryForecast.forecast_id);
              fetchDailySummary(primaryForecast.forecast_id);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching primary site and forecasts:', error);
      }
    };
    fetchPrimarySiteAndForecasts();
  }, []);

  const fetchSites = async () => {
    try {
      const response = await axiosInstance.get('/api/sites/');
      setSites(response.data);
      response.data.forEach((site, index) => {
      });
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const fetchForecasts = async () => {
    try {
      const response = await axiosInstance.get('weather/forecasts/');
      setForecasts(response.data);
    } catch (error) {
      console.error('Error fetching weather forecasts:', error);
    }
  };

  const fetchHourlyForecasts = async (forecastId) => {
    try {
      const response = await axiosInstance.get(`weather/forecasts/${forecastId}/hourly/`);
      const uniqueForecasts = Array.from(new Map(response.data.map(forecast => [`${forecast.date}-${forecast.hour}`, forecast])).values());
      setHourlyForecasts(uniqueForecasts);
    } catch (error) {
      console.error('Error fetching hourly forecasts:', error);
    }
  };

  const fetchAlerts = async (forecastId) => {
    try {
      const response = await axiosInstance.get(`weather/forecasts/${forecastId}/alerts/`);
      setAlerts(response.data);
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  };

  const fetchDailySummary = async (forecastId) => {
    try {
      const response = await axiosInstance.get(`weather/forecasts/${forecastId}/daily/`);
      setDailySummary(response.data[0]);
    } catch (error) {
      console.error('Error fetching daily summary:', error);
    }
  };

  useEffect(() => {
    const fetchLatestConversationMessages = async () => {
      try {
        const conversationsResponse = await axiosInstance.get('/api/chatbot/conversations/');
        const conversations = conversationsResponse.data;
        if (conversations.length > 0) {
          const latestConversationId = conversations[conversations.length - 1].id;
          const messagesResponse = await axiosInstance.get(`/api/chatbot/conversations/${latestConversationId}/messages/`);
          const messages = messagesResponse.data;
          if (messages.length > 0) {
            const firstMessageText = messages[0].message; 
            setDispatchDecision(firstMessageText);
          }
        }
      } catch (error) {
        console.error('Error fetching the latest conversation messages:', error);
      }
    };
    fetchLatestConversationMessages();
  }, []);
  
  const getWeatherIconAndKeyword = (summary) => {
    const lowerCaseSummary = summary.toLowerCase();
    const firstSevenWords = lowerCaseSummary.split(' ').slice(0, 8).join(' ');
    for (const [keywords, icon] of Object.entries(weatherKeywords)) {
      const keywordsArray = keywords.split('|');
      const foundKeyword = keywordsArray.find(keyword => firstSevenWords.includes(keyword.trim()));
      if (foundKeyword) {
        return { icon, keyword: foundKeyword };
      }
    }
    return { icon: null, keyword: "N/A" }; 
  };

  useEffect(() => {
    fetchSites();
    fetchForecasts();
  }, []);

  useEffect(() => {
    if (dailySummary) {
      setDailySummaryText(dailySummary.summary_text);
    }
  }, [dailySummary]);

  const onSelectSite = async (site) => {
    setSelectedSite(site);
    if (site.weather_station) {
      try {
        const response = await axiosInstance.get(`weather/station/${site.weather_station.id}/latest_forecast/`);
        const latestForecast = response.data;
   
        if (latestForecast) {
          setForecasts([latestForecast]);
          fetchHourlyForecasts(latestForecast.forecast_id);
          fetchAlerts(latestForecast.forecast_id);
          fetchDailySummary(latestForecast.forecast_id);
        }
      } catch (error) {
        console.error(`Error fetching forecast for weather station ID: ${site.weather_station.id}`, error);
      }
    } else {
      setForecasts([]);
      setHourlyForecasts([]);
      setAlerts([]);
      setDailySummary(null);
    }
  };

  const dailySummaries = dailySummaryText.split('|||').map(summary => summary.trim());

  const mapContainerStyle = {
    height: '60vh', 
    width: '100%',
  };

  const bottomSectionHeight = '80vh';

  const sectionStyle = {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderColor: theme.palette.background.sectionDivider,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: theme.shape.borderRadius,
  };

  const titleStyle = {
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  };

  const ForecastTable = styled(Table)(({ theme }) => ({
    // minWidth: 650,
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  return (
    <DashboardLayout>
    {!isOnboardingStatusLoaded ? (
      <Grid container spacing={2} sx={{ p: 2 }}>
        {/* Skeleton for the map and weather information */}
        <Grid item xs={12} md={8}>
          <Skeleton variant="rectangular" width="100%" height="60vh" />
          <Skeleton variant="text" sx={{ mt: 2 }} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
        {/* Skeleton for the upcoming debrief section */}
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" width="100%" height="30vh" />
          <Skeleton variant="text" sx={{ mt: 2 }} />
          <Skeleton variant="text" />
        </Grid>
        {/* Skeleton for the weather details section */}
        <Grid item xs={12} md={8}>
          <Skeleton variant="rectangular" width="100%" height="80vh" />
        </Grid>
        {/* Skeleton for the 24-hour forecast section */}
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" width="100%" height="80vh" />
        </Grid>
      </Grid>
    ) : (
      <>
      {!isOnboardingComplete && <OnboardingHandler />}
      {/* Map view */}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Typography>Loading data, please wait...</Typography>
        </Box>
      )}
      <Box 
        sx={{
          flexGrow: 1, 
          p: 2,
          backgroundColor: theme.palette.background.paper,
          margin: theme.spacing(2), 
          borderRadius: theme.shape.borderRadius, 
          boxShadow: `0px 0px 10px ${theme.palette.background.sectionDivider}`, 
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper style={sectionStyle} elevation={3}>
              {/* Conditional rendering based on organization's access level */}
              {organization && organization.access_level !== 'demo' && (
                <Typography variant="subtitle2" color="text.secondary">
                  Select a site to view detailed weather information
                </Typography>
              )}
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedSite ? { lat: parseFloat(selectedSite.latitude), lng: parseFloat(selectedSite.longitude) } : { lat: 45, lng: -95 }}
                zoom={10}
                onLoad={setMap}>
                {sites.map(site => (
                  <Marker
                    key={site.id}
                    position={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }}
                    onClick={() => onSelectSite(site)}
                  />
                ))}
              </GoogleMap>
            </Paper>
          </Grid>

          {/* Upcoming debrief section (placeholder) */}
          <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
            <Paper style={{ ...sectionStyle, flexGrow: 1 }} elevation={3}>
              <Typography variant="h5" style={titleStyle}>Upcoming Debrief Section</Typography>
              {/* Placeholder content */}
            </Paper>
          </Grid>

          {/* Weather Details Section */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: bottomSectionHeight, padding: 2, overflow: 'auto' }}>
              {selectedSite && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {selectedSite.address}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', ml: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">Today's forecast:</Typography>
                    <Typography>{forecasts.find(forecast => forecast.city === selectedSite.city)?.current_condition || 'N/A'}, {forecasts.find(forecast => forecast.city === selectedSite.city)?.current_temperature || 'N/A'}°C</Typography>
                  </Box>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              {dispatchDecision && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 2, marginRight: 4 }}>
                  <Button variant="outlined" color="primary" onClick={() => navigate('/chatbot')}>
                    <Typography variant="subtitle2" color="text.secondary">Dispatch Decision</Typography>
                  </Button>
                  <FormattedMessage message={dispatchDecision} /> {/* Using the FormattedMessage component here */}
                </Box>
              )}
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', overflowX: 'auto', justifyContent: 'center' }}>
                  {dailySummaries.map((summary, index) => {
                    const timeOfDay = summary.split(':')[0];
                    const { icon, keyword } = getWeatherIconAndKeyword(summary);
                    return (
                      <Box key={index} sx={{ minWidth: '120px', textAlign: 'center', borderRight: index !== dailySummaries.length - 1 ? `1px solid ${theme.palette.divider}` : '', px: 2 }}>
                        <Typography variant="subtitle1">{timeOfDay}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 1 }}>
                          {icon}
                          <Typography variant="body2">{keyword}</Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* 24 Hour Forecast Section */}
          <Grid item xs={12} md={4}>
            <Paper style={sectionStyle} elevation={3} sx={{ padding: 2, height: bottomSectionHeight, overflow: 'auto' }}>
            <Button variant="outlined" color="primary" onClick={() => navigate('/weather')} fullWidth>
              <Typography variant="subtitle2" color="text.secondary">24 Hour Forecast</Typography>
            </Button>
              <TableContainer>
                <ForecastTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell align="right">Temperature</TableCell>
                      <TableCell align="right">Forecast</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hourlyForecasts.map((forecast, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {`${forecast.date} ${forecast.hour}:00`}
                        </TableCell>
                        <TableCell align="right">{`${forecast.temperature}°C`}</TableCell>
                        <TableCell align="right">{forecast.forecast}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ForecastTable>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      </>
    )}
    </DashboardLayout>
  );
};

export default HomePage;