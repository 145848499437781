// CompleteComponent.js
import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import BackButton from './BackButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';
import { helix } from 'ldrs'

function FinalOnboardingComponent({ goToPreviousStep }) {
  const navigate = useNavigate();
  const { organization, refreshOrganizationData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  helix.register()

  const completeOnboarding = () => {
    setIsLoading(true);
    if (organization && organization.id) {
      axiosInstance.post(`/api/organizations/${organization.id}/mark_onboarding_complete/`)
        .then(response => {
          localStorage.setItem('onboardingCompleted', 'true');
          refreshOrganizationData();
          navigate('/dashboard');
        })
        .catch(error => {
          console.error('Error completing onboarding:', error);
        });
    } else {
      console.error('Organization ID not found');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, height: '100%', justifyContent: 'center' }}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <l-helix size="45" speed="2.5" color="white"></l-helix>
          <p style={{ color: 'white', marginTop: '20px' }}>Generating your tailored AI...</p>
        </div>
      ) : (
        <>
        <Typography variant="h5" gutterBottom>
          Thank You for Completing the Onboarding!
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          You can now fully utilize the tool to enhance your operations.
        </Typography>
        <Box sx={{ marginTop: 'auto', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Base City Selection" />
          <Button variant="contained" color="primary" onClick={() => {
            completeOnboarding(); 
          }}>
            Complete Onboarding
          </Button>
        </Box>
        </>
      )}
    </Box>
  );
}

export default FinalOnboardingComponent;
