// CommentsCard.js
import React from 'react';
import { Card, Typography, List, ListItem } from '@mui/material';

function CommentsSection({ comments }) {
    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ p: 2 }}>Comments</Typography>
            <List>
                {comments ? comments.map((comment, index) => (
                    <ListItem key={index} divider>
                        <Typography>{comment.text}</Typography>
                    </ListItem>
                )) : (
                    <ListItem>
                        <Typography>No comments available.</Typography>
                    </ListItem>
                )}
            </List>
        </Card>
    );
}

export default CommentsSection;
