// BaseCityModal.js
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import axiosInstance from '../../authentication/axiosInstance';
import { Typography, Button, Box, ThemeProvider, TextField, FormHelperText } from '@mui/material';
import { GoogleMap, StandaloneSearchBox, Marker, useLoadScript } from '@react-google-maps/api';
import theme from '../Theme.js';
import { useAuth } from '../../context/AuthContext';
import BackButton from './BackButton';
import NextButton from './NextButton';

Modal.setAppElement('#root');

const customModalStyle = {
  content: {
    height: '80%',
    width: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    border: '1px solid #2C74B3',
    borderRadius: '8px',
    padding: '20px',
    outline: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 20,
  },
};

function BaseCityModal({ goToNextStep, goToPreviousStep, progressPercentage }) {
  const { user } = useAuth();
  const [address, setAddress] = useState('');
  const [map, setMap] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [initialCoordinates, setInitialCoordinates] = useState(null);
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const searchBoxRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    const fetchPrimarySite = async () => {
      if (user && user.user && user.user.organization) {
        try {
          const response = await axiosInstance.get('/api/organizations/primary_site');
          if (response.data && response.data.primary_site) {
            const site = response.data.primary_site;
            setAddress(site.address);
            setCity(site.city);
            setPostalCode(site.postal_code);
            setStateOrProvince(site.province);
            const coords = { lat: site.latitude, lng: site.longitude };
            setCoordinates(coords);
            setInitialCoordinates(coords);
            console.log("Primary site details loaded.");
          }
        } catch (error) {
          console.error('Error fetching primary site details:', error);
        }
      }
    };

    fetchPrimarySite();
  }, [user, axiosInstance]);

  const onBaseCitySet = () => {
    goToNextStep();
  };

  const onSearchBoxLoaded = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      if (place.geometry) {
        setAddress(place.formatted_address);

        let city = "";
        let postalCode = "";
        let stateOrProvince = "";

        place.address_components.forEach(component => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("postal_code")) {
            postalCode = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            stateOrProvince = component.long_name;
          }
        });

        const newCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };

        setCoordinates(newCoordinates);
        setCity(city);
        setPostalCode(postalCode);
        setStateOrProvince(stateOrProvince);

        if (map) {
          map.panTo(newCoordinates);
        }
      } else {
        console.error("Selected place has no geometry");
      }
    } else {
      console.error("No place selected");
    }
  };

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setCoordinates({ lat: newLat, lng: newLng });
  };

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handleSubmit = async () => {
    // Check if coordinates have changed
    if (JSON.stringify(coordinates) !== JSON.stringify(initialCoordinates)) {
      try {
        const siteData = {
          organization: user.user.organization,
          name: 'N/A',
          address: address,
          city: city,
          province: stateOrProvince,
          postal_code: postalCode,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          walks_area: 0,
          lots_area: 0,
          geofence: 'Null',
        };
        // console.log(siteData)

        const response = await axiosInstance.post('/api/set_primary_site_weather_station/', siteData);
        if (response.status === 200 || response.status === 201) {
          console.log("Site updated successfully.");
        }
      } catch (error) {
        console.error('Failed to update the site:', error);
        setError('Failed to update the site. Please try again.');
        return;
      }
    }
    goToNextStep();
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Select Your Primary Site"
        style={customModalStyle}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <Box sx={{ width: '100%', backgroundColor: '#ccc', marginBottom: '10px' }}>
          <div style={{ height: '4px', backgroundColor: '#2C74B3', width: `${progressPercentage}%`, transition: 'width 0.5s ease' }} />
        </Box>
        <Box sx={{ p: 3, width: '100%' }}>
          <Typography variant="h6" gutterBottom>Welcome! Please Select Your Primary Site.</Typography>
          <Typography variant="body1" gutterBottom>
            Your primary site will be the location displayed on your homepage.
          </Typography>
          <Typography variant="body1" gutterBottom>
            This is the only location available to demo users.
          </Typography>
          <StandaloneSearchBox
            onLoad={onSearchBoxLoaded}
            onPlacesChanged={onPlacesChanged}
          >
            <TextField
              fullWidth
              label="Search Address"
              variant="outlined"
              placeholder="Type your address"
            />
          </StandaloneSearchBox>
          <Box sx={{ height: '45vh', width: '100%', marginTop: 2 }}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={coordinates.lat ? coordinates : { lat: 45, lng: -95 }}
              zoom={4}
              onLoad={onLoad}
            >
              {coordinates.lat !== null && coordinates.lng !== null && (
                <Marker
                  position={coordinates}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                />
              )}
            </GoogleMap>
          </Box>
          {error && (
            <FormHelperText error sx={{ mt: 2, textAlign: 'center' }}>
              {error}
            </FormHelperText>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Time Zone Preference" />
            <NextButton goToNextStep={handleSubmit} nextStepName="Base City Selection" />
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default BaseCityModal;
