// StandbyPayQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function StandbyPayQuestion({ goToNextStep, goToPreviousStep }) {
  const [value, setValue] = useState('');
  const { organization } = useAuth();
  
  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data) {
            setValue(response.data.has_standby_pay ? 'yes' : 'no');
          }
        })
        .catch(error => {
          console.error('Error fetching standby pay preference:', error);
        });
    }
  }, [organization]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        has_standby_pay: value === 'yes',
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving standby pay preference:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Are your employees on stand-by pay?
      </Typography>
      <RadioGroup
        aria-label="standby-pay"
        name="standby-pay"
        value={value}
        onChange={handleChange}
        sx={{ mb: 2 }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Proactive Tools" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Subcontractors" />
      </Box>
    </Box>
  );
}

export default StandbyPayQuestion;
