// MessageList.js
import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../authentication/axiosInstance';
import { useWebSocket } from './../../Websocket';
import './MessageList.css';
import { useAuth } from '../../context/AuthContext';
import { LoadingButton } from '@mui/lab';
import FormattedMessage from '../FormattedDispatchDecision';

const MessageList = ({ conversationId }) => {
  const { chatSocket, isBotTyping, messages, addMessage, setMessages } = useWebSocket();
  const [newMessage, setNewMessage] = useState('');
  const messageListRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const scrollAnchorRef = useRef(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axiosInstance.get(`/api/chatbot/conversations/${conversationId}/messages/`);
        const modifiedMessages = response.data.map(msg => ({
          ...msg,
          sender: msg.user_email 
        }));
        setMessages(modifiedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    
    if (conversationId) {
      fetchMessages();
    }
  }, [conversationId, setMessages]);
  
  const sendMessage = () => {
    if (newMessage.trim() && chatSocket && user) {
      console.log("sent message");
      const messagePayload = {
        sender: user.user.email,
        message: newMessage,
        conversationId: conversationId,
      };
      chatSocket.send(JSON.stringify(messagePayload));
      addMessage({ sender: user.user.email, message: newMessage});
      setNewMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const notifyClients = () => {
    setIsLoading(true);
    axiosInstance.post('/notifications/notify/clients', { 
      conversationId: conversationId,
      organization: user.user.organization
    })
    .then(response => {
      console.log('Notification successful:', response.data);
    })
    .catch(error => {
      console.error('Notification error:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const notifyTeam = () => {
    setIsLoading(true);
    axiosInstance.post('/notifications/notify/team', { 
      conversationId: conversationId,
      organization: user.user.organization,
      sender: user.user.email
    })
    .then(response => {
      console.log('Notification successful:', response.data);
    })
    .catch(error => {
      console.error('Notification error:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    console.log(`MessageList re-rendered, isBotTyping: ${isBotTyping}`);
  }, [isBotTyping]);

  useEffect(() => {
    if (scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]); 

  return (
    <div className="message-list" ref={messageListRef}>
      <div className="messages-container">
        {messages.length > 0 && <FormattedMessage message={messages[0].message} />}
        {/* Render the rest of the messages */}
        {messages.slice(1).map((msg, index) => (
          <div className="message" key={index}>
            <b>{msg.sender}:</b> {msg.message}
          </div>
        ))}
        {isBotTyping && <div className="typing-indicator">Assistant is typing...</div>}
        <div ref={scrollAnchorRef}></div>
      </div>
      {user.user?.is_org_admin && (
        <div className="dispatch-buttons-container">
          <LoadingButton
            loading={isLoading}
            onClick={() => notifyClients('clients')}
            className="dispatch-button"
            variant="contained"
            color="primary"
            disableElevation
          >
            Notify Clients
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            onClick={() => notifyTeam('team')}
            className="dispatch-button"
            variant="contained"
            color="primary"
            disableElevation 
          >
            Notify Team
          </LoadingButton>
        </div>
      )}
      <div className="input-container">
        <input 
          type="text" 
          value={newMessage} 
          onChange={(e) => setNewMessage(e.target.value)} 
          onKeyPress={handleKeyPress} 
          placeholder="Type a message..."
          disabled={isBotTyping}
        />
        <button onClick={sendMessage} disabled={isBotTyping}>Send</button>
      </div>
    </div>
  );
};

export default MessageList;