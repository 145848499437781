// StationSelector.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const WeatherStationSelector = ({ stations, selectedStation, onChange }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="station-selector-label">Weather Station</InputLabel>
            <Select
                labelId="station-selector-label"
                id="station-selector"
                value={selectedStation}
                label="Weather Station"
                onChange={onChange}
            >
                {stations.map((station) => (
                    <MenuItem key={station.id} value={station.id}>
                        {station.station_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default WeatherStationSelector;
