// DashboardLayout.js
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Avatar, Box, CssBaseline, Drawer, IconButton, List, MenuItem, Menu, Toolbar, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, SecondaryListItems } from '../../pages/Dashboard/listItems';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import theme from '../Theme';

const drawerWidth = 240;
const closedDrawerWidth = 72; 

const AppBarShift = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerShift = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'fixed', 
      top: 0,
      height: '100%',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      ...(!open && {
        width: closedDrawerWidth,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const AnimatedIconButton = styled(IconButton)(({ theme, open }) => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: open ? 'translateX(0)' : `translateX(-100%)`, 
}));


export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(
    localStorage.getItem('drawerOpen') === 'true'
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  const handleLogout = async () => {
    try {
      logoutUser();
      navigate('/login');
    } catch (error) {
        console.error('Logout failed:', error);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate('/profile'); 
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleButton = open ? (
    <AnimatedIconButton
      color="inherit"
      aria-label="close drawer"
      onClick={toggleDrawer}
      open={open}
      sx={{
        marginLeft: 'auto',
      }}
    >
      <ChevronLeftIcon />
    </AnimatedIconButton>
  ) : (
    <AnimatedIconButton
      color="inherit"
      aria-label="open drawer"
      onClick={toggleDrawer}
      open={open} 
      sx={{
        marginLeft: 'auto',
        marginRight: '-25px',
      }}
    >
      <MenuIcon />
    </AnimatedIconButton>
  );

  useEffect(() => {
    localStorage.setItem('drawerOpen', open);
  }, [open]);

  const calculatedMarginLeft = open ? drawerWidth : closedDrawerWidth;

  return (
    <Box sx={{ display: 'flex' }} className="dashboard-layout-root">
      <CssBaseline />
      <AppBarShift position="fixed" open={open}>
        <Toolbar>
        <Typography
          component={RouterLink}
          to="/dashboard"
          variant="h6"
          color="inherit"
          noWrap
          sx={{
            flexGrow: 1,
            textDecoration: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingLeft: open ? 0 : theme.spacing(7),
            transition: theme.transitions.create(['padding'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          ConStellation
        </Typography>
          <IconButton
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <Avatar alt="User Name" src="/static/images/avatar/1.jpg" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBarShift>
      <DrawerShift variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative', 
            px: [1],
          }}
        >
          {toggleButton}
        </Toolbar>
        <Divider />
        <Box
          sx={{
            overflowX: 'hidden', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <List component="nav">
            <MainListItems />
          </List>
          <Box>
            <Divider sx={{ my: 1 }} />
            <SecondaryListItems />
          </Box>
        </Box>
      </DrawerShift>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          marginLeft: `${calculatedMarginLeft}px`, 
          width: `calc(100% - ${calculatedMarginLeft}px)`, 
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
      <Toolbar />
      {children}
      </Box>
    </Box>
  );
}
