import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from './Theme';

const IndentedTypography = styled(Typography)({
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
});

const parseMessage = (message) => {
  const sections = { 'Weather Analysis': '', 'Suggested Dispatch Actions': '', 'Summary': '' };
  let currentHeading = '';
  message.split('\n').forEach(line => {
    if (line.includes('Weather Analysis')) currentHeading = 'Weather Analysis';
    else if (line.includes('Suggested Dispatch Actions')) currentHeading = 'Suggested Dispatch Actions';
    else if (line.includes('Summary')) currentHeading = 'Summary';

    if (currentHeading && sections[currentHeading] !== undefined && !line.includes(currentHeading)) {
      sections[currentHeading] += line + ' ';
    }
  });
  return sections;
};

const FormattedMessage = ({ message }) => {
  const sections = parseMessage(message);

  return (
    <div>
      {Object.keys(sections).map((section) => (
        sections[section] && (
          <div key={section}>
            <Typography variant="subtitle2" color="text.secondary">{section}:</Typography>
            <IndentedTypography variant="body1">{sections[section]}</IndentedTypography>
          </div>
        )
      ))}
    </div>
  );
};

export default FormattedMessage;
