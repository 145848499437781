// FacilitiesOperationQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function FacilitiesOperationQuestion({ goToNextStep, goToPreviousStep }) {
  const [operation, setOperation] = useState('');
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data) {
            setOperation(response.data.services_24_7_facilities ? 'yes' : 'no');
          }
        })
        .catch(error => {
          console.error('Error fetching facilities operation preference:', error);
        });
    }
  }, [organization]);

  const handleOperationChange = (event) => {
    setOperation(event.target.value);
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        services_24_7_facilities: operation === 'yes',
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving facilities operation preference:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>Do you service any facilities that operate 24/7?</Typography>
      <RadioGroup
        aria-label="facilities-operation"
        name="facilities-operation"
        value={operation}
        onChange={handleOperationChange}
        sx={{ mb: 2 }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="Snow Depth" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Tool Importance" />
      </Box>
    </Box>
  );
}

export default FacilitiesOperationQuestion;
