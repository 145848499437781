// ToolImportanceQuestion.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import NextButton from './NextButton';
import BackButton from './BackButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../authentication/axiosInstance';

function ToolImportanceQuestion({ goToNextStep, goToPreviousStep }) {
  const [importance, setImportance] = useState({
    improvedService: false,
    reducedCost: false,
    reducedRisk: false,
    improvedTransparency: false,
  });
  const { organization } = useAuth();

  useEffect(() => {
    if (organization && organization.id) {
      axiosInstance.get(`/api/organizations/${organization.id}/preferences/`)
        .then(response => {
          if (response.data) {
            setImportance({
              improvedService: response.data.importance_improved_service || false,
              reducedCost: response.data.importance_reduced_cost || false,
              reducedRisk: response.data.importance_reduced_risk || false,
              improvedTransparency: response.data.importance_improved_transparency || false,
            });
          }
        })
        .catch(error => {
          console.error('Error fetching tool importance preferences:', error);
        });
    }
  }, [organization]);  

  const handleChange = (event) => {
    setImportance({ ...importance, [event.target.name]: event.target.checked });
  };

  const saveAndProceed = () => {
    if (organization && organization.id) {
      const preferenceData = {
        importance_improved_service: importance.improvedService,
        importance_reduced_cost: importance.reducedCost,
        importance_reduced_risk: importance.reducedRisk,
        importance_improved_transparency: importance.improvedTransparency,
      };

      axiosInstance.put(`/api/organizations/${organization.id}/preferences/`, preferenceData)
        .then(response => {
          goToNextStep();
        })
        .catch(error => {
          console.error('Error saving tool importance preferences:', error);
        });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        What is most important to you from this tool? Please rank the following:
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={importance.improvedService} onChange={handleChange} name="improvedService" />}
          label="Improved service"
        />
        <FormControlLabel
          control={<Checkbox checked={importance.reducedCost} onChange={handleChange} name="reducedCost" />}
          label="Reduced cost"
        />
        <FormControlLabel
          control={<Checkbox checked={importance.reducedRisk} onChange={handleChange} name="reducedRisk" />}
          label="Reduced liability risk"
        />
        <FormControlLabel
          control={<Checkbox checked={importance.improvedTransparency} onChange={handleChange} name="improvedTransparency" />}
          label="Improved transparency"
        />
      </FormGroup>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <BackButton goToPreviousStep={goToPreviousStep} previousStepName="24/7 Facilities" />
        <NextButton goToNextStep={saveAndProceed} nextStepName="Time Zone Preference" />
      </Box>
    </Box>
  );
}

export default ToolImportanceQuestion;
