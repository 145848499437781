// src/authentication/csrf.js
export async function getCsrfToken() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  try {
      const response = await fetch(`${baseUrl}/api/csrf/`, {
          method: 'GET',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.csrfToken;
  } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
      throw error;
  }
}
