// PasswordResetForm.js
import React, { useState } from 'react';
import axiosInstance from '../../authentication/axiosInstance';
import {
  Button, TextField, Box, Typography, Container
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';

export default function PasswordResetForm() {
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/reset/${uid}/${token}/`, {
          new_password1: newPassword,
          new_password2: confirmPassword
      });
      if (response.status === 200) {
        setMessage('Your password has been reset successfully');
      }
    } catch (error) {
      setMessage('Failed to reset password');
      console.error('Password reset error', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Set New Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          {message && (
            <Typography color="textSecondary" align="center">
              {message}
            </Typography>
          )}
          <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Link to="/login" style={{ textDecoration: 'none', marginRight: 10 }}>
              <Typography variant="body2">Login</Typography>
            </Link>
            <Link to="/register-organization" style={{ textDecoration: 'none' }}>
              <Typography variant="body2">Register</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
