// UserRegistration.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function Register() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [user_name, setUser_name] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get('email');
    const user_nameParam = queryParams.get('user_name'); 
    const orgId = queryParams.get('organization_id');
    const tokenParam = queryParams.get('token');

    if (emailParam) setEmail(emailParam);
    if (user_nameParam) setUser_name(decodeURIComponent(user_nameParam));
    if (orgId) setOrganizationId(orgId);
    if (tokenParam) setToken(tokenParam);
    for (let [key, value] of queryParams.entries()) {
      console.log(`${key}: ${value}`);
    }

    if (!orgId) {
      setErrorMessage('Registration is only available through company invite');
    } else {
      axiosInstance.get(`/api/organizations/${orgId}`).then(response => {
        setOrganizationName(response.data.name);
      }).catch(error => {
        console.error('Failed to fetch organization:', error);
        setErrorMessage('Failed to fetch organization details.');
      });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const user_name = data.get('user_name');
    const password = data.get('password');
    const phone_number = data.get('phone_number');
  
    try {
      setLoading(true);
      const registerResponse = await axiosInstance.post('/api/register', {
        email,
        password,
        phone_number,
        user_name,
        organization: organizationId,
        token,
      });
  
    if (registerResponse.data) {
      setTimeout(() => {
        setLoading(false);
        navigate('/dashboard');
      }, 2000);
    } else {
      throw new Error('Registration failed');
    }
    } catch (error) {
      let message = 'Registration or login failed. Please try again.';
      if (error.response && error.response.data) {
        message = error.response.data.detail || error.response.data.error || message;
      }
      setErrorMessage(message);
    }
  };

  const isSignUpDisabled = !token;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ alignSelf: 'flex-start', mb: 2 }}>
          <Button variant="contained" href="/" sx={{ ml: 0, mt: -6 }}>
            Back to Home
          </Button>
        </Box>
        <Avatar sx={{ m: 1, bgcolor: 'text.primary' }}>
          <img src={"/images/consalt_favicon.png"} alt="Logo" />
        </Avatar>
        <Typography component="h1" variant="h5">
          User Sign Up
        </Typography>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <CircularProgress />
          </div>
        )}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="organization"
                label="Organization"
                name="organization"
                autoComplete="organization"
                value={organizationName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                InputProps={{
                  readOnly: true, 
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="user_name"
                label="Full Name"
                name="user_name"
                autoComplete="user_name"
                value={user_name}
                onChange={(event) => setUser_name(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phone_number"
                label="Phone Number"
                name="phone_number"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions, and updates via email."
              />
            </Grid>
          </Grid>
          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSignUpDisabled}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}