// ForecastsDataCard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const DailyForecast = ({ forecastData }) => {
    const formatDataForGraph = (data) => {
        return data.map(item => ({
            ...item,
            hour: parseInt(item.hour_formatted),
            temperature: parseInt(item.temperature),
            precipitation_probability: parseInt(item.precipitation_probability.replace('%', ''))
        }));
    };

    const formattedData = formatDataForGraph(forecastData);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Hourly Forecast Trends</Typography>
                <LineChart
                    width={600}
                    height={300}
                    data={formattedData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="hour" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="temperature" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line yAxisId="right" type="monotone" dataKey="precipitation_probability" stroke="#82ca9d" />
                </LineChart>
            </CardContent>
        </Card>
    );
};

export default DailyForecast;
