// OrganizationRegistration.js
import * as React from 'react';
import { useState } from 'react';
import axiosInstance from '../authentication/axiosInstance';
import Cookies from 'js-cookie';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';

const csrfToken = Cookies.get('csrftoken');

export default function OrganizationRegistration() {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  const [accessLevel, setAccessLevel] = useState('standard'); 
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminName, setAdminName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get('plan');
    if (['demo', 'standard', 'pro'].includes(plan)) {
      setAccessLevel(plan);
    }
  }, [location.search]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        // Step 1: Create the organization
        const organizationResponse = await axiosInstance.post('/api/organizations/', {
            name,
            access_level: accessLevel
        });
        console.log('Organization created:', organizationResponse.data);
  
        // Step 2: Create the admin user linked to the organization
        const adminUserResponse = await axiosInstance.post('/api/register', {
            email: adminEmail,
            password: adminPassword,
            name: adminName,
            is_org_admin: true,
            phone_number: adminPhoneNumber,
            organization: organizationResponse.data.id, 
        });
        console.log('Admin user created:', adminUserResponse.data);
        
        if (adminUserResponse.status >= 200 && adminUserResponse.status < 300) {
            console.log("Login successful:", adminUserResponse.data);
            navigate('/dashboard');
        } else {
            throw new Error('Login failed');
        }
    } catch (error) {
      console.error('Error during organization and admin creation or login:', error.response ? error.response.data : error);
      setErrorMessage('Registration or login failed. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ alignSelf: 'flex-start', mb: 2 }}>
          <Button variant="contained" href="/" sx={{ ml: 0, mt: -6 }}>
            Back to Home
          </Button>
        </Box>
        <Avatar sx={{ m: 1, bgcolor: 'white' }}>
          <img src={"/images/Consalt_Favicon.png"} alt="Organization Icon" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register Organization
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Organization Name"
            name="name"
            autoComplete="name"
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="accessLevel-label">Plan</InputLabel>
            <Select
              labelId="accessLevel-label"
              id="accessLevel"
              value={accessLevel}
              label="Plan"
              name="accessLevel"
              onChange={(e) => setAccessLevel(e.target.value)}
            >
              <MenuItem value="demo">Demo</MenuItem>
              <MenuItem value="standard">Standard</MenuItem>
              <MenuItem value="pro">Pro</MenuItem>
            </Select>
          </FormControl>
          <Typography component="h6" variant="h6" sx={{ mt: 4 }}>
            Admin User Information
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="adminEmail"
            label="Admin Email"
            name="adminEmail"
            autoComplete="admin-email"
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="adminPassword"
            label="Admin Password"
            type="password"
            id="adminPassword"
            autoComplete="new-password"
            onChange={(e) => setAdminPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="adminName"
            label="Admin Name"
            name="adminName"
            autoComplete="admin-name"
            onChange={(e) => setAdminName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="adminPhoneNumber"
            label="Admin Phone Number"
            name="adminPhoneNumber"
            autoComplete="admin-phone-number"
            onChange={(e) => setAdminPhoneNumber(e.target.value)}
          />
          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register Organization and Admin
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
