// import * as React from 'react';
import React, { useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import WeatherIcon from '@mui/icons-material/WbSunny';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AdminPanelSettingsSharpIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const MainListItems = () => {
  return (
  <React.Fragment>
    <ListItemButton component={RouterLink} to="/dashboard">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/weather">
      <ListItemIcon>
        <WeatherIcon />
      </ListItemIcon>
      <ListItemText primary="Weather" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/chatbot">
      <ListItemIcon>
        <ChatIcon />
      </ListItemIcon>
      <ListItemText primary="Chatbot" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/debrief">
      <ListItemIcon>
        <QueryStatsIcon />
      </ListItemIcon>
      <ListItemText primary="Debrief" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/clients">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Clients" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/sites">
      <ListItemIcon>
        <MapsHomeWorkIcon />
      </ListItemIcon>
      <ListItemText primary="Sites" />
    </ListItemButton>
  </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  const { user } = useAuth();

  return (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Help
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Contact Us" />
    </ListItemButton>
    {user.user?.is_superuser && (
      <ListItemButton component="a" href={`${process.env.REACT_APP_API_BASE_URL}/admin`} target='_blank'>
        <ListItemIcon>
          <AdminPanelSettingsSharpIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItemButton>
    )}
  </React.Fragment>
  );
};
